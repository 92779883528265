import { API } from "../../../api/constants"
import { Employee } from "../types/Types"
import { authenticatedAxiosInstance } from "../../../auth/AuthenticatedAxios"

export const getEmployeeByIdData = (data: string) => authenticatedAxiosInstance.get(API + `/employee/get/${data}`).then((res) => res.data)

export const updateEmployee = (data: { id: string; body: Employee }) =>
    authenticatedAxiosInstance.put(API + "/employee/update/" + data.id, data.body).then((res) => res.data)

export const updateEmployeeKcGroup = (data: { kcid: string; gid: string }) =>
    authenticatedAxiosInstance.put(API + `/kc/user/${data.kcid}/update-rbac-role/${data.gid}`).then((res) => res.data)

// TODO Datatype any
// add Doc to Employee
export const postNewDocToEmployee = (data: { id: string; data: any }) =>
    authenticatedAxiosInstance
        .patch(API + "/employee/doc/array/" + data.id, data.data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then((res) => res.data)

// delete Doc from single Employee
export const deleteDocFromEmployee = (data: { id: string; file: string }) =>
    authenticatedAxiosInstance.patch(API + "/employee/doc/delete/" + data.id, { file: data.file }).then((res) => res.data)

export const resetPWFromEmployee = (data: string) =>
    authenticatedAxiosInstance.put(API + "/kc/user/" + data + "/sendPasswordResetEmail").then((res) => res.data)

// KC USER WORK
export const getUserGroupsFromKC = () => authenticatedAxiosInstance.get(API + "/kc/groups").then((res) => res.data)

// export const getUserLocationsFromKC = () => axios.get('')
