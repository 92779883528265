import { motion } from "framer-motion";
import { CustomerFilterModalType } from "../types/Types";

export default function CustomerFilterModal({
  visible,
  setVisible,
  archived,
  setarchived,
  useFilter,
  filterRemove,
}: CustomerFilterModalType) {
  return (
    <motion.div
      initial={{
        x: "150%",
      }}
      animate={{
        x: visible ? 0 : "150%",
      }}
      transition={{
        type: "ease",
        duration: 0.3,
      }}
      className={`absolute top-20 right-4 pt-8 h-fit w-96 rounded-default flex flex-col shadow-lg bg-white px-4 pb-4 z-[99]`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6 absolute top-4 left-4 cursor-pointer"
        onClick={setVisible}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
      
      <div className="flex flex-col gap-1 pt-4">
        <h3 className="py-1 font-medium">Archiv anzeigen</h3>
        <div className="flex flex-row gap-6 pl-2">
          <input
            type="radio"
            name="radio-group-customer-1"
            className="radio"
            value="option1"
            checked={archived === "hidden"}
            onChange={() => setarchived("hidden")}
          />
          <p>Archivierte Kunden nicht anzeigen</p>
        </div>
        <div className="flex flex-row gap-6 pl-2">
          <input
            type="radio"
            name="radio-group-customer-1"
            className="radio"
            value="option2"
            checked={archived === "show"}
            onChange={() => setarchived("show")}
          />
          <p>Archivierte Kunden mit anzeigen</p>
        </div>
        <div className="flex flex-row gap-6 pl-2">
          <input
            type="radio"
            name="radio-group-customer-1"
            className="radio"
            value="option3"
            checked={archived === "only"}
            onChange={() => setarchived("only")}
          />
          <p>Nur Archivierte Kunden anzeigen</p>
        </div>
      </div>
      <div className="flex flex-row items-center justify-between mt-6">
        <p className="underline text-sm cursor-pointer" onClick={filterRemove}>
          Zurücksetzen
        </p>
        <button className="btn btn-primary btn-outline" onClick={useFilter}>
          Anwenden
        </button>
      </div>
    </motion.div>
  );
}
