import React, { useContext, useEffect, useState } from "react"
import BaseModal from "../../../components/layout/BaseModal"
import ErrorPage from "../../404/ErrorPage"
import Loading from "../../../components/common/Loading"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { ModalContext } from "../../../components/contexts/ModalContext"
import { useUpdateEmployeeObjectMutation } from "../api/services"
import { ModalEmployeeChangeType } from "../types/types"
import { getPaginatedData } from "../../api/apiCalls"


export default function ChangeEmployeeModal({ modalId, employee, setemployee, originalData, objectId }: ModalEmployeeChangeType) {
    const [show, setshow] = useState(false)
    const [page, setpage] = useState(1)
    const handlePageChange = (page: number) => {
        setpage(page)
    }
    const modalCon = useContext(ModalContext)
 
    // TODO also a use for a get-all-request without pagination
    const {
        data: employeeData,
        error: employeeError,
        isLoading: employeeIsLoading,
    } = useQuery({
        queryKey: ["employeeData" + page],
        queryFn: () => getPaginatedData("employee", page, 15),
    })

    const isChecked = (_id: string) => employee?.includes(_id)

    const handleClick = (id: string) => {
        // remove it, if id is already part of the array
        if (employee.includes(id)) {
            setemployee(employee.filter((e) => e !== id))
        } else {
            setemployee((prevData: any) => [...prevData, id])
        }
    }

    useEffect(() => {
        if (
            originalData?.every((element, index) => element === employee[index]) &&
            employee?.every((element, index) => element === originalData[index])
        ) {
            setshow(false)
        } else {
            setshow(true)
        }
    }, [employee, originalData])

    const queryClient = useQueryClient()
    const { mutate: employeeUpdateObject } = useUpdateEmployeeObjectMutation(queryClient, modalCon, 1)
    const handleUpdateEmployeeObject = () => {
        const updateObject = { id: objectId, body: { employee_ids: employee } }
        employeeUpdateObject(updateObject)
    }

    return (
        <BaseModal
            title={"Mitarbeiter zuordnen"}
            modalId={modalId}
            bottomRow={
                <div className="flex flex-row justify-end">
                    <button className="btn btn-warning" disabled={!show} onClick={() => handleUpdateEmployeeObject()}>
                        Ändern
                    </button>
                </div>
            }>
            {employeeError && <ErrorPage />}
            {employeeIsLoading && <Loading />}
            {/* {employeeData && (
                <>
                    <div className="w-full h-full overflow-y-scroll pt-2">
                        {employeeData?.map((item: any, index: any) => (
                            <div
                                onClick={() => handleClick(item._id)}
                                key={index}
                                className={`w-full border-b flex py-2 px-8 hover:bg-gray-100 cursor-pointer items-center gap-4`}>
                                <input type="checkbox" checked={isChecked(item._id)} className="checkbox" />
                                <div>
                                    <p className="leading-5 font-medium">
                                        {item.first_name} {item.last_name}
                                    </p>
                                    <p className="text-sm text-gray-400">{item.position}</p>
                                </div>
                            </div>
                        ))}
                        <div className="flex justify-center w-full p-4">
                            <Pagination
                                defaultCurrent={1}
                                current={page}
                                onChange={handlePageChange}
                                total={employeeData.totalDocs}
                                showSizeChanger={false}
                            />
                        </div>
                    </div>
                </>
            )} */}
        </BaseModal>
    )
}
