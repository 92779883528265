import { QueryClient, useMutation } from "@tanstack/react-query"
import { useContext } from "react"
import { ActionMessageContext } from "../../components/contexts/ActionMessageContext"
import { archiveDataGeneral, deleteDataGeneral, getDocumentFromAnyType, postNewDataGeneral } from "./apiCalls"
import { ArchiveDataParams, DeleteDataParams, PostNewDataParams } from "./Types/Types"

// download document
export const useDownloadDocumentMutation = () => {
    const alert = useContext(ActionMessageContext)
    return useMutation<Blob, Error, { id: string; s3Key: string; filetype: string; filename: string }>({
        mutationFn: getDocumentFromAnyType,
        onSuccess: (data, variables) => {
            const url = window.URL.createObjectURL(data)
            const a = document.createElement("a")
            a.href = url
            a.download = variables.filename
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)
            a.remove()
            alert?.alertAnimation("download", "Dokument")
        },
        onError: (err) => {
            alert?.alertAnimation("download", "Dokument", true)
            console.log(err)
        },
    })
}

// post function - add customer / object / employee
export const usePostDataMutation = (queryClient: QueryClient, modalCon: any, pageType: string, refreshData: string, clearInput: () => void) => {
    const alert = useContext(ActionMessageContext)
    return useMutation<unknown, Error, PostNewDataParams>({
        mutationFn: postNewDataGeneral,
        onSuccess: () => {
            clearInput()
            modalCon?.trigger(0)
            alert?.alertAnimation("add", pageType)
            queryClient.invalidateQueries({
                queryKey: [refreshData],
            })
        },
        onError: (err) => {
            alert?.alertAnimation("add", pageType, true)
            console.log(err)
        },
    })
}

// delete function - delete customer / object / employee
export const useDeleteDataMutation = (queryClient: QueryClient, modalCon: any, pageType: string, refreshData: string) => {
    const alert = useContext(ActionMessageContext)
    return useMutation<unknown, Error, DeleteDataParams>({
        mutationFn: deleteDataGeneral,
        onSuccess: () => {
            alert?.alertAnimation("delete", pageType)
            modalCon?.trigger(4)
            queryClient.invalidateQueries({
                queryKey: [refreshData],
            })
        },
        onError: (err) => {
            alert?.alertAnimation("delete", pageType, true)
            console.log(err)
        },
    })
}

// archive function - update customer / object / employee
export const useArchiveDataMutation = (queryClient: QueryClient, modalConFunction: () => void, refreshData: string, pageType: string) => {
    const alert = useContext(ActionMessageContext)
    return useMutation<unknown, Error, ArchiveDataParams>({
        mutationFn: archiveDataGeneral,
        onSuccess: () => {
            alert?.alertAnimation("update", pageType)
            modalConFunction()
            queryClient.invalidateQueries({ queryKey: [refreshData] })
        },
        onError: (err) => {
            alert?.alertAnimation("update", pageType, true)
            console.log(err)
        },
    })
}
