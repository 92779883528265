import { preventKeyDown } from "../../services/functions"
import { BaseInputAreaType } from "../Types"
import { Input } from "antd"

const { TextArea } = Input

export default function BaseInputArea(
    props: BaseInputAreaType
) {
    return (
        <div
            className={`flex flex-col w-full ${props.classProps}`}>
            <label className="text-sm px-2 pb-1">
                {props.label}
            </label>
            <TextArea
                onKeyDown={
                    props.prevent
                        ? preventKeyDown
                        : () => {}
                }
                value={props.value}
                required={props.required}
                disabled={props.disabled}
                onChange={(e) =>
                    props.onChange(e.target.value)
                }
                placeholder={props.placeholder}
                autoSize={{
                    minRows: 2,
                    maxRows: 6,
                }}
                className="rounded-default w-full"
            />
        </div>
    )
}
