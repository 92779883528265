import { useContext } from "react";
import BasePage from "../../components/layout/BasePage";
import HeadBanner from "../../components/elements/BaseHeadBanner";
import AddTaskModal from "./components/modal/AddTaskModal";
import { ModalContext } from "../../components/contexts/ModalContext";
import TaskDashboardCard from "./components/mainpage/TaskDashboardCard";
import ShortOverview from "./components/mainpage/ShortOverviewElement";

export default function Aufgaben() {

    const modalCon = useContext(ModalContext)

    return (
        <BasePage>
            <HeadBanner title='Aufgaben' button={
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 cursor-pointer" onClick={() => modalCon?.trigger(1)}><path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
            }/>

            {/* <div className="w-full grow flex h-full bg-black"/> */}
            <section className="w-full h-[88vh] py-4 flex flex-col overflow-x-hidden overflow-y-scroll pl-4 pr-3">
                <div className="w-full gap-6 flex flex-row h-1/2  pb-3">
                    <TaskDashboardCard width="w-3/5" headline="Dringende Aufgaben (in 48h)"/>
                    <TaskDashboardCard width="w-2/5" standingOrder headline="Daueraufträge"/>
                </div>
                <div className="w-full gap-6 flex flex-row h-1/2 pt-3">
                    <div className="h-full w-80 flex flex-col gap-2">
                        <div className=" flex flex-col gap-2 shadow-lg rounded-default bg-white p-4 grow">
                            <h3 className="uppercase font-semibold leading-3">Kurzübersicht</h3>
                            <p className="text-sm italic leading-3 pb-2">Statistik dieser Woche</p>
                            <ShortOverview description='Offene Aufträge:' index={123}/>
                            <ShortOverview description='Abgeschlossene Aufträge:' index={192}/>
                            <ShortOverview description='Überzogene Aufträge:' index={7}/>
                        </div>
                        <button className="btn btn-primary btn-outline" onClick={() => alert('PopUp to Add Dauerauftrag')}>Dauerauftrag anlegen</button>
                        <button className="btn btn-primary" onClick={() =>  alert('Navigate to Übersicht mit allen Aufträgen')}>Auftragsübersicht</button>
                    </div>
                    {/* <div className="w-full h-full bg-black"></div> */}
                    <TaskDashboardCard headline="Diese Woche Fällig"/>
                </div>
            </section>

            <AddTaskModal/>
        </BasePage>
    )
}