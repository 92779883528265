import { useMutation } from "@tanstack/react-query"
import { ActionMessageContext } from "../../components/contexts/ActionMessageContext"
import { resendMail } from "./apiCalls"
import { useContext } from "react"

export const useResendMailMutation = () => {
    const alert = useContext(ActionMessageContext)
    return useMutation<unknown, Error, string>({
        mutationFn: resendMail,
        onSuccess: () => {
            alert?.alertAnimation("resend", "Mail")
        },
        onError: (err) => {
            alert?.alertAnimation("resend", "Mailversand", true)
            console.log(err)
        },
    })
}
