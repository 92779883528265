import Keycloak from "keycloak-js";

/**
 * This is the keycloak configuration object.
 */

// the keycloak object is created here but not connected to the server yet!! --> see index.tsx
const keycloak = new Keycloak({
    url: "https://auth.zentrale.wicode.io",
    realm: "1001-01",
    clientId: "react-frontend",

});
export default keycloak;
