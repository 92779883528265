import { motion } from "framer-motion"
import { NewOfferScreen3 } from "../../types"
import Dropzone from "react-dropzone"

export default function BillModalScreen3({
    visible,
    documents,
    setdocuments,
}: NewOfferScreen3) {
    const handleNewDocs = (files: any) => {
        setdocuments(files)
    }

    return (
        <motion.div
            initial={{
                x: "100%",
            }}
            animate={{
                x: visible ? 0 : "100%",
            }}
            transition={{
                type: "ease",
            }}
            className="absolute top-0 left-0 w-full h-full pl-3 z-30 bg-white pb-2 overflow-y-scroll">
            <Dropzone
                onDrop={(acceptedFiles) =>
                    handleNewDocs(acceptedFiles)
                }>
                {({
                    getRootProps,
                    getInputProps,
                }) => (
                    <div className="h-full flex flex-col">
                        <h3 className="font-semibold text-lg pt-4">
                            Leistungskatalog,
                            Bilder, o. Ä. anhängen
                        </h3>
                        <div
                            className={`w-full grow cursor-pointer flex border rounded-default mt-2 ${
                                !documents
                                    ? "items-center justify-center"
                                    : "flex-wrap p-4 gap-4"
                            }`}
                            {...getRootProps()}>
                            <input
                                {...getInputProps()}
                            />
                            {!documents && (
                                <p className="uppercase font-semibold text-gray-400">
                                    Dokumente hier
                                    ablegen oder
                                    Klicken und
                                    auswählen
                                </p>
                            )}
                            {documents &&
                                documents?.map(
                                    (
                                        item: any,
                                        index: number
                                    ) => (
                                        <div className="h-32 w-32 border rounded-default flex items-center justify-center">
                                            <p className="truncate">
                                                {
                                                    item.name
                                                }
                                            </p>
                                        </div>
                                    )
                                )}
                        </div>
                    </div>
                )}
            </Dropzone>
        </motion.div>
    )
}
