import { API } from "../../../api/constants"
import { authenticatedAxiosInstance } from "../../../auth/AuthenticatedAxios"

type HelpMessageType = {
    topic: string
    description: string
}

// ==================================================== BANKING =====================================================  //
export const sendHelpMessage = (
    msg: HelpMessageType
) =>
    authenticatedAxiosInstance
        .post(API + "/help", msg)
        .then((res) => res.data)
