import { motion } from "framer-motion"
import BaseInputField from "../../../../components/elements/BaseInputField"
import { useState } from "react"
import { ModalTaskScreen2Type } from "../../types/Types"

export default function ModalTaskScreen2({
    visible,
}: ModalTaskScreen2Type) {
    const [taskcount, settaskcount] =
        useState<number>(1)

    return (
        <motion.div
            initial={{
                x: "100%",
            }}
            animate={{
                x: visible ? 0 : "100%",
            }}
            transition={{
                type: "ease",
            }}
            className="w-full h-full z-20 absolute top-0 left-0 bg-white pt-2 overflow-y-scroll pb-4 pl-2">
            {/* {Array.from({
                length: taskcount,
            }).map((_, index) => (
                // <BaseInputField
                //     classProps="mt-3"
                //     key={index}
                //     label={
                //         "Aufgabe " + (index + 1)
                //     }
                //     type={"text"}
                // />
            ))} */}
            <div
                className="flex pl-4 text-sm items-center pt-2 gap-3 cursor-pointer"
                onClick={() =>
                    settaskcount(taskcount + 1)
                }>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                </svg>
                <p>Weitere Aufgabe hinzufügen</p>
            </div>
            {taskcount > 1 && (
                <div
                    className="flex pl-4 text-sm items-center pt-1 gap-3 cursor-pointer"
                    onClick={() =>
                        settaskcount(
                            taskcount - 1
                        )
                    }>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    </svg>
                    <p>Aufgabe entfernen</p>
                </div>
            )}
        </motion.div>
    )
}
