import { useEffect, useState } from "react"
import BaseModal from "../../../components/layout/BaseModal"
import Leistungsverzeichnis from "./Leistungsverzeichnis"
import { useQueryClient } from "@tanstack/react-query"
import { useUpdateListOfServiesOfObjectMutation } from "../api/services"

type EditLvModalType = {
    modalId: number
    objectId: string
    data: any // TODO
}

export default function EditLvModal(props: EditLvModalType) {
    const [serviceList, setserviceList] = useState({})
    useEffect(() => {
        setserviceList(props.data ?? {})
    }, [props.data])

    const queryClient = useQueryClient()
    const { mutate: updateListOfServices } = useUpdateListOfServiesOfObjectMutation(queryClient, props.modalId)
    const safeLv = () => {
        console.log(serviceList)
        updateListOfServices({ id: props.objectId, serviceList: serviceList })
    }

    const changeValue = (key: string, value: string) => {
        setserviceList((prevObjekt) => ({
            ...prevObjekt,
            [key]: value,
        }))
    }

    return (
        <BaseModal
            modalId={props.modalId}
            title="Leistungsverzeichnis bearbeiten"
            bottomRow={
                Object.keys(serviceList).length !== 50 ? (
                    <p className="text-center font-semibold text-error">Kein Leistungsverzeichnis angelegt. Bitte alle Felder ausfüllen</p>
                ) : (
                    <div className="flex justify-end">
                        <button
                            className="btn btn-success"
                            disabled={Object.keys(serviceList).length !== 50 || serviceList === props.data}
                            onClick={safeLv}>
                            Speichern
                        </button>
                    </div>
                )
            }>
            <Leistungsverzeichnis data={props?.data ?? {}} changeInput={changeValue} />
        </BaseModal>
    )
}
