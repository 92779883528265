import { useNavigate } from "react-router-dom"
import { BillTableRowType } from "../../types"
import dayjs from "dayjs"
import { useEffect } from "react"

export default function BillTableRow({ id, title, customer, object, date, delivery, amount, type, archived }: BillTableRowType) {
    const navigate = useNavigate()
    const target = dayjs(delivery)
    const today = dayjs()

    const calculateTotal = (positions: any[]): string => {
        return positions
            ?.map((position) => position.amount * position.singlePrice * (1 + 0.01 * position.tax))
            .reduce((acc, value) => acc + value, 0)
            .toFixed(2)
            .toString()
            .replace(".", ",")
    }

    useEffect(() => {
        console.log(object)
    }, [])

    return (
        <tr
            onClick={() => navigate("/buchhaltung/rechnung/" + id)}
            className={`border-b border-gray-200 cursor-pointer ${archived && "opacity-40 bg-gray-100"}`}>
            <td className="hidden lg:table-cell">
                <p className="line-clamp-1 text-base text-center">{dayjs(date).format("DD.MM.YYYY")}</p>
            </td>
            <td>
                <p title={title} className="line-clamp-1 text-base text-center">
                    Rechnung {title}
                </p>
            </td>
            <td className="hidden lg:table-cell">
                <p title={customer} className="line-clamp-1 text-base text-center">
                    {type}
                </p>
            </td>
            <td>
                <p className="line-clamp-1 text-base text-center">{calculateTotal(amount)} €</p>
            </td>
            <td className="hidden md:table-cell">
                <p title={customer} className="line-clamp-1 text-base text-center">
                    {customer}
                </p>
            </td>
            <td className="hidden md:table-cell">
                <p title={customer} className="line-clamp-1 text-base text-center">
                    {object?.adress?.street} {object?.adress?.nr}, {object?.adress?.plz} {object?.adress?.city}
                </p>
            </td>
        </tr>
    )
}
