import { HeadBannerType } from "../Types"

export default function BaseHeadBanner({
    title,
    button,
}: HeadBannerType) {
    return (
        <div className="w-full bg-white shadow-lg px-20 py-8 xl:p-8 rounded-default flex flex-row justify-between items-center relative">
            <h1 className="text-2xl font-bold uppercase tracking-wider truncate">
                {title}
            </h1>
            {button}
        </div>
    )
}
