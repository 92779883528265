import React, { Key, useContext } from "react"
import BaseDocumentElement from "../../../../../components/elements/BaseDocumentElement"
import { DocumentDataType } from "../../../../../components/Types"
import { PR } from "../../../../../data/Types"
import dayjs from "dayjs"
import { ModalContext } from "../../../../../components/contexts/ModalContext"

type BillDetailPaymentsAndFilesType = {
    connectedPaymentRequirements: PR[]
    setpayment: (item: PR) => void
    data: {
        additional_documents: DocumentDataType[]
    }
    handleDownload: (item: { s3Key: string; filename: string }) => void
}

export default function BillDetailPaymentsAndFiles(props: BillDetailPaymentsAndFilesType) {
    const modalCon = useContext(ModalContext)

    return (
        <div className="mt-4 w-full h-fit max-h-[16vh] rounded-default shadow-lg bg-white p-4 flex flex-row">
            <div className="flex flex-col w-full">
                <p className="w-full border-b pb-2 font-medium">Zugeordnete Zahlungsanforderungen</p>
                <div className="overflow-y-scroll">
                    {!props.connectedPaymentRequirements || props.connectedPaymentRequirements.length === 0 ? (
                        <p className="text-center uppercase text-sm text-gray-300 font-medium pt-1">Keine Zahlungen zugeordnet</p>
                    ) : (
                        props.connectedPaymentRequirements?.map((item: PR, index: Key) => (
                            <PaymentRow
                                key={index}
                                item={item}
                                openModal={() => {
                                    props.setpayment(item)
                                    modalCon?.trigger(16)
                                }}
                            />
                        ))
                    )}
                </div>
            </div>
            <div className="flex flex-col w-full">
                <p className="w-full border-b pb-2 font-medium">Zugeordnete Dateien</p>
                <div className="overflow-y-scroll">
                    {!props.data.additional_documents || props.data.additional_documents.length === 0 ? (
                        <p className="text-center uppercase text-sm text-gray-300 font-medium pt-1">Keine Dokumente hochgeladen</p>
                    ) : (
                        props.data?.additional_documents.map((item: DocumentDataType, index: Key) => (
                            <BaseDocumentElement
                                key={index}
                                title={item.title}
                                timestamp={item.timestamp}
                                downloadFunc={() => props.handleDownload({ s3Key: item.s3Key, filename: item.title })}
                            />
                        ))
                    )}
                </div>
            </div>
        </div>
    )
}

type PaymentRowType = {
    item: PR
    openModal: () => void
}

function PaymentRow(props: PaymentRowType) {
    return (
        <div className="w-full flex flex-row items-center gap-4 p-2 ">
            <p className="text-xm text-gray-400 h-fit">Erstellt am:</p>
            <p>{dayjs(props.item.invoice_date).format("DD.MM.YYYY")}</p>
            <p className="text-xm text-gray-400 h-fit">Fällig am:</p>
            <p>{dayjs(props.item.due_date).format("DD.MM.YYYY")}</p>
            <p className="text-xm text-gray-400 h-fit">Betrag:</p>
            <p>{props.item.expected_payment.toFixed(2).toString().replace(".", ",")} €</p>
            <p className="text-xm text-gray-400 h-fit">Status:</p>
            <p
                className={`${
                    props.item.status === "settled" ? "text-green-500" : props.item.status === "unpaid" ? "text-red-600" : "text-gray-400"
                } font-medium`}>
                {props.item.status === "settled" ? "Beglichen" : props.item.status === "unpaid" ? "Offen" : "Storniert"}
            </p>
            {props.item.status !== "canceled" && (
                <button className="btn btn-sm btn-ghost" onClick={props.openModal}>
                    Mahnung
                </button>
            )}
        </div>
    )
}
