import React, { useContext } from "react"
import BaseModal from "../../../components/layout/BaseModal"
import ErrorPage from "../../404/ErrorPage"
import Loading from "../../../components/common/Loading"
import { useQuery } from "@tanstack/react-query"
import { getAllCustomerData } from "../../Kunden/api/apiCalls"
import { ModalContext } from "../../../components/contexts/ModalContext"
import { ChangeCustomerModalType } from "../types/types"

export default function ChangeCustomerModal({ modalId, customer, setcustomer, isCustomerChanged }: ChangeCustomerModalType) {
    const modalCon = useContext(ModalContext)
    const {
        data: allCustomerData,
        error: allCustomerError,
        isLoading: allCustomerIsLoading,
    } = useQuery({
        queryKey: ["customerData"],
        queryFn: getAllCustomerData,
    })

    return (
        <BaseModal
            title={"Kunde zuordnen"}
            modalId={modalId}
            bottomRow={
                <div className="flex flex-row justify-end">
                    <button disabled={isCustomerChanged} className="btn btn-warning" onClick={() => modalCon?.trigger(0)}>
                        Ändern
                    </button>
                </div>
            }>
            {allCustomerError && <ErrorPage />}
            {allCustomerIsLoading && <Loading />}
            {allCustomerData && (
                <>
                    <div className="w-full h-full overflow-y-scroll pt-2">
                        {allCustomerData.map((item: any, index: any) => (
                            <div
                                onClick={() =>
                                    setcustomer({
                                        id: item._id,
                                        name: item.customer.name + " | " + item.administrator.name,
                                    })
                                }
                                key={index}
                                className={`w-full border-b flex flex-col py-2 px-8 hover:bg-gray-100 cursor-pointer ${
                                    customer.id === item._id ? "bg-gray-100" : ""
                                }`}>
                                <p>{item.customer.name}</p>
                                <p>{item.customer.adress.street}</p>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </BaseModal>
    )
}
