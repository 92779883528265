import { motion } from 'framer-motion'
import { EmployeeFilterModalType } from '../types/Types'

export default function EmployeeFilterModal({ visible, setvisible, archived, setarchived, position, setposition, useFilter, filterRemove }: EmployeeFilterModalType) {
    return (
        <motion.div
            initial={{
                y: "-150%",
                opacity: 0
            }}
            animate={{
                y: visible ? 0 : "-150%",
                opacity: visible ? 1 : 0
            }}
            transition={{
                type: "ease",
                duration: 0.3,
            }}
            className={`absolute top-16 right-4 pt-8 h-fit w-96 rounded-default flex flex-col shadow-2xl bg-white px-4 pb-4 z-[99]`}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 absolute top-4 left-4 cursor-pointer"
                onClick={setvisible}
            >
                <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
                />
            </svg>
            <div className="flex flex-col gap-1 pt-4">
                <h3 className="py-1 font-medium">Archiv anzeigen</h3>
                <div className="flex flex-row gap-6 pl-2">
                    <input
                        type="radio"
                        name="radio-group-employee-1"
                        className="radio"
                        value="option1"
                        checked={archived === "hidden"}
                        onChange={() => setarchived("hidden")}
                    />
                    <p>Archivierte Mitarbeiter nicht anzeigen</p>
                </div>
                <div className="flex flex-row gap-6 pl-2">
                    <input
                        type="radio"
                        name="radio-group-employee-1"
                        className="radio"
                        value="option2"
                        checked={archived === "show"}
                        onChange={() => setarchived("show")}
                    />
                    <p>Archivierte Mitarbeiter mit anzeigen</p>
                </div>
                <div className="flex flex-row gap-6 pl-2">
                    <input
                        type="radio"
                        name="radio-group-employee-1"
                        className="radio"
                        value="option3"
                        checked={archived === "only"}
                        onChange={() => setarchived("only")}
                    />
                    <p>Nur Archivierte Mitarbeiter anzeigen</p>
                </div>
            </div>
            <div className="flex flex-col gap-1 pt-4">
                <h3 className="py-1 font-medium">Bestimmte Position anzeigen</h3>
                <div className="flex flex-row gap-6 pl-2">
                    <input
                        type="radio"
                        name="radio-group-employee-2"
                        className="radio"
                        value="option1"
                        checked={position === "all"}
                        onChange={() => setposition("all")}
                    />
                    <p>Alle</p>
                </div>
                <div className="flex flex-row gap-6 pl-2">
                    <input
                        type="radio"
                        name="radio-group-employee-2"
                        className="radio"
                        value="option2"
                        checked={position === "4"}
                        onChange={() => setposition("4")}
                    />
                    <p>Mitarbeiter</p>
                </div>
                <div className="flex flex-row gap-6 pl-2">
                    <input
                        type="radio"
                        name="radio-group-employee-2"
                        className="radio"
                        value="option3"
                        checked={position === "3"}
                        onChange={() => setposition("3")}
                    />
                    <p>Backoffice 3</p>
                </div>
                <div className="flex flex-row gap-6 pl-2">
                    <input
                        type="radio"
                        name="radio-group-employee-2"
                        className="radio"
                        value="option4"
                        checked={position === "2"}
                        onChange={() => setposition("2")}
                    />
                    <p>Backoffice 2</p>
                </div>
                <div className="flex flex-row gap-6 pl-2">
                    <input
                        type="radio"
                        name="radio-group-employee-2"
                        className="radio"
                        value="option5"
                        checked={position === "1"}
                        onChange={() => setposition("1")}
                    />
                    <p>Backoffice 1</p>
                </div>
                <div className="flex flex-row gap-6 pl-2">
                    <input
                        type="radio"
                        name="radio-group-employee-2"
                        className="radio"
                        value="option6"
                        checked={position === "0"}
                        onChange={() => setposition("0")}
                    />
                    <p>Admin</p>
                </div>
            </div>
            <div className="flex flex-row items-center justify-between mt-6">
                <p className="underline text-sm cursor-pointer" onClick={filterRemove}>
                    Zurücksetzen
                </p>
                <button className="btn btn-primary btn-outline" onClick={useFilter}>
                    Anwenden
                </button>
            </div>
        </motion.div>
    )
}
