import dayjs from "dayjs"
import { MailReportType } from "../../data/Types"
import { useContext } from "react"
import { SidePanelContext } from "../contexts/SidePanelContext"
import { returnHumanizedMailtype } from "../../services/functions"

type BaseMailPreviewDataRowType = {
    index: number
    item: MailReportType
    sidePanelConId: number
    mailType?: boolean
    setResendUri: (e: string) => void
    setMailPreviewContent: (e: string) => void
}

export default function BaseMailPreviewDataRow(props: BaseMailPreviewDataRowType) {
    const sidePanelCon = useContext(SidePanelContext)
    return (
        <div className={`flex flex-row px-8 w-full ${props.mailType ? "border-b py-2" : "pb-4"}`}>
            <div className="flex flex-col gap-1 text-xs text-black/30 w-2/3">
                {props.mailType && <p>Type: {returnHumanizedMailtype(props.item.mailType)}</p>}
                <p>Mail Nummer: {props.index + 1}</p>
                <p>Versandt am: {dayjs(props.item.date).format("DD.MM.YYYY")}</p>
                <p className="truncate">
                    Versandt an:{" "}
                    {props.item?.mailReceivers?.map(
                        (receiver: string) =>
                            receiver + (props.item?.mailReceivers.indexOf(receiver) + 1 !== props.item.mailReceivers.length ? ", " : "")
                    )}
                </p>
            </div>
            <div className="flex flex-col gap-1 text-xs text-black/30 items-end w-1/3">
                <button
                    onClick={() => {
                        props.setResendUri(props.item.resendUri)
                        props.setMailPreviewContent(props.item.mailContent)
                        sidePanelCon?.trigger(props.sidePanelConId)
                    }}
                    disabled={!props.item.mailContent}
                    className="px-8 btn btn-outline btn-primary btn-xs">
                    Vorschau
                </button>
            </div>
        </div>
    )
}
