import BasePage from "../../../components/layout/BasePage"
import HeadBanner from "../../../components/elements/BaseHeadBanner"
import AddObjectModal from "../components/AddObjectModal"
import { useCallback, useContext, useMemo, useState } from "react"
import { ModalContext } from "../../../components/contexts/ModalContext"
import ObjectCard from "../components/ObjectCard"
import ObjectActionModal from "../components/ObjectActionModal"
import ModalCheckAction from "../../../components/elements/ModalCheckAction"
import ObjectFilterModal from "../components/ObjectFilterModal"
import BaseFilterButton from "../../../components/elements/BaseFilterButton"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import Loading from "../../../components/common/Loading"
import ErrorPage from "../../404/ErrorPage"
import { useNavigate } from "react-router-dom"
import { ObjectType } from "../types/types"
import { preventKeyDown } from "../../../services/functions"
import { Roles } from "../../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../../../components/authentication/RenderWhenAuthorized"
import { Pagination } from "antd"
import { getPaginatedData } from "../../api/apiCalls"
import { useArchiveDataMutation, useDeleteDataMutation } from "../../api/services"

export default function Objekte() {
    const [page, setpage] = useState(1)
    const handlePageChange = (page: number) => {
        setpage(page)
    }
    const { error, data, isLoading } = useQuery({
        queryKey: ["objectData" + page],
        queryFn: () => getPaginatedData("object", page, 20),
    })

    const navigate = useNavigate()

    // Filter Variables
    const [filter, setFilter] = useState(0)
    const [archived, setarchived] = useState("hidden")
    const [showFilter, setShowFilter] = useState(false)
    const [searchTerm, setsearchTerm] = useState("")
    const modalCon = useContext(ModalContext)

    const handleRemoveFilter = () => {
        setarchived("hidden")
        setFilter(0)
    }

    const handleUseFilter = () => {
        setShowFilter(false)
        setFilter(filter + 1)
    }

    // filter settings for showing or not showing archived objects
    const filterArchived = useCallback(
        (data: ObjectType[]) => {
            if (archived === "hidden") {
                return data?.filter((item) => item.archived === false)
            } else if (archived === "show") {
                return data
            } else if (archived === "only") {
                return data?.filter((item) => item.archived === true)
            } else {
                return data
            }
        },
        [filter]
    )

    // Filter the displayed objects according to filter, user input, usw.
    const displayedObjects = useMemo(() => {
        if (data?.docs) {
            const alphabaticalSortData = data.docs?.sort((a: { adress: { street: String } }, b: { adress: { street: String } }) => {
                if (a.adress.street < b.adress.street) {
                    return -1
                } else if (a.adress.street > b.adress.street) {
                    return 1
                } else {
                    return 0
                }
            })
            const archivedData = filterArchived(alphabaticalSortData)
            if (!searchTerm) return archivedData
            return archivedData?.filter((item: any) => {
                const { street, nr, city, plz } = item.adress
                const fullAddress = `${street} ${nr} ${city} ${plz}`.toLowerCase()
                return fullAddress.includes(searchTerm.toLowerCase())
            })
        }
    }, [filterArchived, data, searchTerm])

    const queryClient = useQueryClient()
    const [selectedId, setSelectedId] = useState("")
    const { mutate: archiveObject } = useArchiveDataMutation(queryClient, () => modalCon?.trigger(3), "objectData" + page, "Objektstatus")
    const handleArchiveObject = (archive: boolean) => {
        archiveObject({
            id: selectedId,
            pageType: "object",
            body: { archived: archive },
        })
    }
    const { mutate: deleteObject } = useDeleteDataMutation(queryClient, modalCon, "Objekt", "objectData" + page)
    const handleDeleteObject = () => {
        deleteObject({ id: selectedId, pageType: "object" })
    }
    console.log(displayedObjects)
    return (
        <BasePage>
            <HeadBanner
                title={"Objekte"}
                button={
                    <RenderWhenAuthorized requiresAll={[Roles.objekte_create]}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-8 h-8 cursor-pointer hidden lg:block"
                            onClick={() => {
                                modalCon?.trigger(1)
                            }}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </RenderWhenAuthorized>
                }
            />
            {isLoading && <Loading />}
            {error && <ErrorPage />}
            {data && !isLoading && !error && (
                <>
                    <section className="w-full flex flex-row justify-between gap-4 py-4 md:px-4 items-center relative">
                        <input
                            onKeyDown={preventKeyDown}
                            onChange={(e) => setsearchTerm(e.target.value)}
                            type="text"
                            value={searchTerm ?? null}
                            placeholder="Objekte suchen..."
                            className="w-full max-w-xs rounded-default bg-none shadow-lg p-4 text-base-200"
                        />

                        <BaseFilterButton func={() => setShowFilter(!showFilter)} />
                        <ObjectFilterModal
                            visible={showFilter}
                            setVisible={() => setShowFilter(false)}
                            archived={archived}
                            setarchived={setarchived}
                            useFilter={handleUseFilter}
                            filterRemove={handleRemoveFilter}
                        />
                    </section>
                    <section className="w-full overflow-y-scroll inline-grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 pb-8 sm:px-4 max-h-[75vh]">
                        {displayedObjects?.map((item: any, index: number) => (
                            <ObjectCard
                                key={index}
                                offers={item.offerCount}
                                bills={item.billCount}
                                adress={item.adress}
                                archived={item.archived}
                                openFunc={() => navigate("/objekte/" + item._id)}
                                archiveFunc={() => {
                                    setSelectedId(item._id)
                                    item.archived ? modalCon?.trigger(5) : modalCon?.trigger(3)
                                }}
                                deleteFunc={() => {
                                    setSelectedId(item._id)
                                    modalCon?.trigger(4)
                                }}
                            />
                        ))}
                    </section>
                    {(!displayedObjects || displayedObjects.length === 0) && (
                        <div className="flex flex-col justify-center items-center w-full">
                            <p className="text-gray-300 uppercase font-medium pt-4">Keine erstellten Objekte</p>
                            {(searchTerm !== "" || searchTerm !== undefined) && (
                                <button className="btn btn-ghost mt-2 btn-outline max-w-xs" onClick={() => setsearchTerm("")}>
                                    Eingabe zurücksetzen
                                </button>
                            )}
                        </div>
                    )}
                    <div className="flex justify-center w-full p-4">
                        <Pagination
                            defaultCurrent={1}
                            pageSize={20}
                            current={page}
                            onChange={handlePageChange}
                            total={data.totalDocs}
                            showSizeChanger={false}
                        />
                    </div>
                    <AddObjectModal modalId={1} />
                    <ObjectActionModal />
                    <ModalCheckAction
                        modalId={3}
                        headline={"Wirklich Archivieren?"}
                        text={
                            "Sind Sie sich sicher, dieses Objekt archivieren wollen? Es wird nicht mehr in der Liste Ihrer aktiven Objekte angezeigt werden."
                        }
                        buttonText={"Archivieren"}
                        buttonColor="bg-blue-300 border-none hover:bg-blue-400"
                        func={() => handleArchiveObject(true)}
                    />
                    <ModalCheckAction
                        modalId={5}
                        headline={"Archivieren rückgängig machen?"}
                        text={
                            "Sind Sie sich sicher, dieses Objekt nicht mehr archivieren wollen? Es wird ab sofort wieder in der Liste Ihrer aktiven Objekte angezeigt werden."
                        }
                        buttonText={"Anzeigen"}
                        buttonColor="bg-blue-300 border-none hover:bg-blue-400"
                        func={() => handleArchiveObject(false)}
                    />
                    <ModalCheckAction
                        modalId={4}
                        headline={"Wirklich Löschen?"}
                        text={"Sind Sie sich sicher, dieses Objekt zu löschen? Alle dazugehörigen Daten werden ebenfalls gelöscht."}
                        buttonText={"Löschen"}
                        buttonColor="btn-error"
                        func={() => handleDeleteObject()}
                    />
                </>
            )}
        </BasePage>
    )
}
