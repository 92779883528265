import { ModalCheckActionType } from "../Types"
import BaseModal from "../layout/BaseModal"

export default function ModalCheckAction({
    modalId,
    headline,
    text,
    buttonText,
    buttonColor,
    func,
}: ModalCheckActionType) {
    return (
        <BaseModal
            title={headline}
            size
            modalId={modalId}>
            <p className="max-w-md py-2 px-2">
                {text}
            </p>
            <div className="flex justify-end">
                <button
                    className={`btn ${buttonColor}`}
                    onClick={func}>
                    {buttonText}
                </button>
            </div>
        </BaseModal>
    )
}
