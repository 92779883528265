import { motion } from "framer-motion"
import { NewBillScreen1 } from "../../types"
import BaseInputField from "../../../../components/elements/BaseInputField"
import BaseInputSelection from "../../../../components/elements/BaseInputSelection"
import BaseSelection from "../../../../components/elements/BaseSelection"
import BaseCheckbox from "../../../../components/elements/BaseCheckbox"

export default function BillModalScreen1(props: NewBillScreen1) {
    return (
        <motion.div
            initial={{
                x: 0,
            }}
            animate={{
                x: props.visible ? 0 : "-100%",
            }}
            transition={{
                type: "ease",
            }}
            className="absolute top-0 left-0 w-full h-full overflow-x-hidden overflow-y-scroll pb-4 pl-2">
            <div className="flex gap-4">
                <BaseSelection
                    label={"Rechnungstyp *"}
                    placeholder={""}
                    classProps="mt-4"
                    data={["Rechnung", "Jahresrechnung", "Dauerrechnung"]}
                    setId={props.setbillType}
                    defaultValue="Rechnung"
                />
                <BaseSelection
                    label={"Kategorie *"}
                    placeholder={""}
                    classProps="mt-4 !w-64"
                    data={["Hausmeister", "Schlüsseldienst", "Bau"]}
                    setId={props.setbillCategorie}
                    defaultValue="Hausmeister"
                />
            </div>
            <BaseInputSelection
                classProps="pt-2"
                setId={props.setcustomer}
                label={"Kunde *"}
                placeholder={"Kunde auswählen"}
                data={props.customerArray ?? []}
            />

            <BaseInputSelection
                classProps="pt-2"
                setId={props.setobject}
                label={"Objekt *"}
                placeholder={"Objekt auswählen"}
                data={props.objectArray ?? []}
            />
            {props.customerId !== "" && props.objectArray?.length === 0 && (
                <p className="text-xs text-gray-300">Diesem Kunde sind keine Objekte zugewiesen.</p>
            )}
            <BaseSelection
                label={"Rechnungsfrist in Tagen *"}
                placeholder={""}
                classProps="mt-2"
                data={["7", "14", "28"]}
                setId={props.setbillDue}
                defaultValue="28"
            />
            {props.billType !== "Rechnung" && (
                <BaseSelection
                    label={"Zahltag des Monats *"}
                    placeholder={""}
                    classProps="mt-2"
                    data={["1", "15", "28"]}
                    setId={props.setbillDay}
                    defaultValue="28"
                />
            )}
            <BaseInputField
                prevent
                label={"Leistungsdatum *"}
                type={"date"}
                value={props.deliveryDate}
                onChange={props.setdeliveryDate}
                classProps="mt-2"
            />
            <BaseCheckbox
                check={props.manualReminderEnabled}
                setcheck={props.setmanualReminderEnabled}
                classProps="justify-start mt-4"
                label={"Mahnungsfristen manuell setzen"}
            />
            {props.manualReminderEnabled && (
                <div className="flex gap-4">
                    <BaseInputField
                        label={"Nach wieviel Tagen wird gemahnt?"}
                        type={"number"}
                        value={props.reminderStartOffsetInDays}
                        onChange={props.setreminderStartOffsetInDays}
                        classProps="mt-4"
                    />
                    <BaseInputField
                        label={"In welchem Abstand wird gemahnt? (in Tagen)"}
                        type={"number"}
                        value={props.reminderIntervalInDays}
                        onChange={props.setreminderIntervalInDays}
                        classProps="mt-4"
                    />
                    <BaseInputField
                        label={"Wieviele Mahnungen werden verschickt?"}
                        type={"number"}
                        value={props.maxReminders}
                        onChange={props.setmaxReminders}
                        classProps="mt-4"
                    />
                </div>
            )}
        </motion.div>
    )
}
