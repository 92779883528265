import { Roles } from "../../../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../../../../components/authentication/RenderWhenAuthorized"
import { TransactionElementType } from "../../types"

export function TransactionElement(
    props: TransactionElementType
) {
    return (
        <div className="w-full px-4 py-2 flex flex-row gap-2 relative border-b">
            <div className="w-16 font-semibold">
                {props.date}
            </div>
            <div
                className={`w-32 text-right font-bold ${
                    props.status === "UNASSIGNED"
                        ? "text-red-600"
                        : "text-green-500"
                }`}>
                {props.amount.toString()} €
            </div>
            <div className="w-full pl-2 pr-8">
                <p className="line-clamp-1 text-gray-400">
                    {props.note} {props.note}
                </p>
            </div>
            <RenderWhenAuthorized
                requiresAll={[
                    Roles.buchhaltung_zahlungen_read_write,
                ]}>
                <div className="absolute right-4">
                    <p title="Rechnung zuordnen">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 cursor-pointer"
                            onClick={
                                props.openModal
                            }>
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                            />
                        </svg>
                    </p>
                </div>
            </RenderWhenAuthorized>
        </div>
    )
}
