import dayjs from "dayjs"
import { TransactionRowType } from "../../../types"

export function TransactionRow(
    props: TransactionRowType
) {
    return (
        <div className="flex flex-row items-center gap-8 py-1 relative">
            <p className="text-sm w-36">
                Betrag:{" "}
                <span className="text-base font-medium pl-1">
                    {" "}
                    {props?.transaction?.paymentInformation?.transactionAmount.amount
                        .toString()
                        .replace(".", ",")}{" "}
                    €
                </span>
            </p>
            <p className="text-sm w-36">
                Datum:{" "}
                <span className="text-base font-medium pl-1">
                    {" "}
                    {dayjs(
                        props?.transaction
                            ?.bookingTimestamp
                    ).format("DD.MM.YYYY")}
                </span>
            </p>
            <p className="text-sm truncate w-[28rem]">
                Verwendungszweck:{" "}
                <span className="text-base font-medium pl-2">
                    {" "}
                    {
                        props?.transaction
                            ?.paymentInformation
                            ?.remittanceText
                    }{" "}
                    €
                </span>
            </p>
            <svg
                onClick={props.remove}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 absolute right-0 cursor-pointer">
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                />
            </svg>
        </div>
    )
}
