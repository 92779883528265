import BasePage from "../../../components/layout/BasePage"
import HeadBanner from "../../../components/elements/BaseHeadBanner"
import { useContext, useState } from "react"
import { ActionMessageContext } from "../../../components/contexts/ActionMessageContext"
import Transactions from "../components/Transactions/Transactions"
import AssignTransactionModal from "../components/Transactions/AssignTransactionModal"
import { ModalContext } from "../../../components/contexts/ModalContext"
import { transactionElementType } from "../types"
import { useQuery } from "@tanstack/react-query"
import Charts from "../components/Charts/Charts"

export default function Dashboard() {
    const alert = useContext(ActionMessageContext)
    const modalCon = useContext(ModalContext)
    const [transaction, settransaction] = useState<transactionElementType>()

    // TODO - still need a route for all payreqs [ or at least those, which are not selected yet ]
    // const { data, error, isLoading } = useQuery({
    //     queryKey: ["paymentsData"],
    //     queryFn: () => getPaymentRequirementsData,
    // })

    return (
        <BasePage>
            <HeadBanner title="Dashboard" />
            <div className="flex flex-row gap-4 h-fit w-full">
                <Charts />
                <Transactions openModal={() => modalCon?.trigger(1)} func={settransaction} />
            </div>
            {/* <AssignTransactionModal data={data} error={error} isLoading={isLoading} modalId={1} transaction={transaction} /> */}
        </BasePage>
    )
}
