import React from "react";
import dayjs from "dayjs";
import { EmployeeCardModal } from "../../Objekte/types/types";
import { Roles } from "../../../auth/RoleMapping";
import { RenderWhenAuthorized } from "../../../components/authentication/RenderWhenAuthorized";

export default function EmployeeCard({
  item,
  selected,
  setEmployee,
  deleteFunc,
  archiveFunc,
}: EmployeeCardModal) {
  return (
    <div
      onClick={setEmployee}
      className={`bg-white cursor-pointer flex flex-col w-full h-fit rounded-default shadow-md p-4 relative border ${item.archived && 'opacity-60 text-gray-400'} ${
        selected ? "border-black" : ""
      }`}
    >
      <h3 className="font-semibold text-xl">
        {item.first_name} {item.last_name}
      </h3>
      <div className="border-b w-full my-2" />
      <p className="text-sm">
        Geburtstag: {dayjs(item.geb).format("DD.MM.YYYY")}
      </p>
      <p className="text-sm">Position: {item.position}</p>
      {/* NOTE currently disabled bc no function in prod */}
      {/* <p className="text-sm">Objekte in Betreuung: {item.objects?.length ?? 0} </p> */}
      <p className="text-sm">Offene Aufgaben: {item.tasks?.length ?? 0}</p>
      
      <RenderWhenAuthorized
                        requiresAll={[Roles.mitarbeiter_read_write, Roles.mitarbeiter_delete]}>

      <div className="flex flex-row justify-end gap-2 grow items-center">
        <p title="Archivieren" onClick={archiveFunc}>
          {item.archived ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 9.776c.112-.017.227-.026.344-.026h15.812c.117 0 .232.009.344.026m-16.5 0a2.25 2.25 0 00-1.883 2.542l.857 6a2.25 2.25 0 002.227 1.932H19.05a2.25 2.25 0 002.227-1.932l.857-6a2.25 2.25 0 00-1.883-2.542m-16.5 0V6A2.25 2.25 0 016 3.75h3.879a1.5 1.5 0 011.06.44l2.122 2.12a1.5 1.5 0 001.06.44H18A2.25 2.25 0 0120.25 9v.776"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 13.5l3 3m0 0l3-3m-3 3v-6m1.06-4.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
              />
            </svg>
          )}
        </p>
        <p title="Löschen" onClick={deleteFunc}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 cursor-pointer"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
        </p>
      </div>
                        </RenderWhenAuthorized>
    </div>
  );
}
