import { QueryClient, useMutation } from "@tanstack/react-query"
import { Employee } from "../types/Types"
import {
    deleteDocFromEmployee,
    postNewDocToEmployee,
    resetPWFromEmployee,
    updateEmployee,
    updateEmployeeKcGroup,
} from "./apiCalls"
import { useContext } from "react"
import { ActionMessageContext } from "../../../components/contexts/ActionMessageContext"

// update employee data
export const useUpdateEmployeeMutation = (queryClient: QueryClient, id: string) => {
    const alert = useContext(ActionMessageContext)
    return useMutation<unknown, Error, { id: string; body: Employee }>({
        mutationFn: updateEmployee,
        onSuccess: (data, variables) => {
            alert?.alertAnimation("update", "Mitarbeiter")
            queryClient.invalidateQueries({ queryKey: ["employeeData" + id] })
            queryClient.invalidateQueries({ queryKey: ["employeeData"] })
        },
        onError: (err) => {
            alert?.alertAnimation("update", "Mitarbeiter", true)
            console.log(err)
        },
    })
}

// update employee kc group
export const useUpdateEmployeeKcGroupMutation = (queryClient: QueryClient, id: string) => {
    const alert = useContext(ActionMessageContext)
    return useMutation<unknown, Error, { kcid: string; gid: string }>({
        mutationFn: updateEmployeeKcGroup,
        onSuccess: (data, variables) => {
            alert?.alertAnimation("update", "Mitarbeiter")
            queryClient.invalidateQueries({ queryKey: ["employeeData" + id] })
            queryClient.invalidateQueries({ queryKey: ["employeeData"] })
        },
        onError: (err) => {
            alert?.alertAnimation("update", "Mitarbeiter", true)
            console.log(err)
        },
    })
}

// reset pw from user
export const usePWResetEmployeeMutation = (queryClient: QueryClient, modalCon: any, id: string) => {
    const alert = useContext(ActionMessageContext)
    return useMutation<unknown, Error, string>({
        mutationFn: resetPWFromEmployee,
        onSuccess: (data, variables) => {
            alert?.alertAnimation("update", "Mitarbeiter Passwort")
            modalCon?.trigger(7)
            queryClient.invalidateQueries({ queryKey: ["employeeData" + id] })
        },
        onError: (err) => {
            alert?.alertAnimation("delete", "Mitarbeiter", true)
            console.log(err)
        },
    })
}

// upload document(s) to employee
export const useAddDocToEmployeeMutation = (queryClient: QueryClient, id: string) => {
    const alert = useContext(ActionMessageContext)
    return useMutation<unknown, Error, { id: string; data: any }>({
        mutationFn: postNewDocToEmployee,
        onSuccess: () => {
            alert?.alertAnimation("add", "Dokument")
            queryClient.invalidateQueries({ queryKey: ["employeeData" + id] })
        },
        onError: (err) => {
            alert?.alertAnimation("add", "Dokument", true)
            console.log(err)
        },
    })
}

// delete doc-data from employee & from s3
export const usePatchDocEmployeeMutation = (queryClient: QueryClient, modalCon: any, modalConId: number, id: string) => {
    const alert = useContext(ActionMessageContext)
    return useMutation<unknown, Error, { id: string; file: string }>({
        mutationFn: deleteDocFromEmployee,
        onSuccess: (data, variables) => {
            alert?.alertAnimation("delete", "Dokument")
            modalCon?.trigger(modalConId)
            queryClient.invalidateQueries({ queryKey: ["employeeData" + id] })
        },
        onError: (err) => {
            alert?.alertAnimation("delete", "Dokument", true)
            console.log(err)
        },
    })
}
