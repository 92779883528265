import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

type PaymentChartType = {
    labels: string[];
    data: any;
    setChart : (e: any) => void;
}

export function PaymentChart (props : PaymentChartType) {
    const chartRef = useRef<any>(null);  

  useEffect(() => {
    const myChartRef = chartRef.current.getContext("2d");
    const paymentChart = new Chart(myChartRef, {
      type: 'bar',
      data: {
        labels: props.labels,
        datasets: props.data,
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });

    props.setChart(paymentChart)
    return () => {
        paymentChart.destroy();
      };
  }, []);



  return (
      <canvas ref={chartRef} />
  );
}

