import { Select } from "antd"
import { useMemo } from "react"
import { BaseInputSelectionType } from "../Types"

export default function BaseInputSelection(props: BaseInputSelectionType) {
    // Transforms the input array in the needed format
    const arrayData = useMemo(() => {
        return props.data.map((obj) => ({
            value: obj._id,
            label: obj?.customer?.name
                ? obj?.customer?.name // if data is a customer array
                : obj?.name
                ? obj.name + " | " + obj?.adress?.street + " " + obj?.adress?.nr + ", " + obj?.adress?.plz + " " + obj?.adress?.city // if data is admin (verwalter) array
                : obj?.adress?.street + " " + obj?.adress?.nr + ", " + obj?.adress?.plz + " " + obj?.adress?.city || // if data is object array
                  "keine Eingabe",
        }))
    }, [props.data])

    // filter the input
    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

    // change & set data
    const onChange = (value: string) => {
        props.setId(value)
    }

    return (
        <div className={`flex flex-col w-full z-10 ${props.classProps}`}>
            <label className="text-sm pl-2 pb-1">{props.label}</label>
            <Select
                disabled={arrayData.length === 0 || arrayData === undefined}
                showSearch
                value={props.value ?? undefined}
                placeholder={props.placeholder}
                optionFilterProp="children"
                onChange={onChange}
                filterOption={filterOption}
                options={arrayData}
                style={{ height: "3.5rem" }}
            />
        </div>
    )
}
