import { useContext, useState } from "react"
import BaseInputField from "../../../components/elements/BaseInputField"
import BaseModal from "../../../components/layout/BaseModal"
import { AddObjectModalType, ObjectType } from "../types/types"
import { getAllCustomerData } from "../../Kunden/api/apiCalls"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import ErrorPage from "../../404/ErrorPage"
import Loading from "../../../components/common/Loading"
import { ModalContext } from "../../../components/contexts/ModalContext"
import BaseInputSelection from "../../../components/elements/BaseInputSelection"
import { usePostDataMutation } from "../../api/services"

export default function AddObjectModal({ modalId }: AddObjectModalType) {
    const {
        data: customer,
        error: errorCustomer,
        isLoading: isLoadingCustomer,
    } = useQuery({
        queryKey: ["customerData"],
        queryFn: getAllCustomerData,
    })
    const queryClient = useQueryClient()
    const modalCon = useContext(ModalContext)

    const [street, setstreet] = useState<string>("")
    const [nr, setnr] = useState<string>("")
    const [plz, setplz] = useState<string>("")
    const [city, setcity] = useState<string>("")
    const [customer_id, setCustomerId] = useState<string | undefined>(undefined)

    const clearInput = () => {
        setstreet("")
        setnr("")
        setplz("")
        setcity("")
        setCustomerId(undefined)
    }

    const { mutate: addObject } = usePostDataMutation(queryClient, modalCon, "Objekt", "objectData1", clearInput)
    const handleNewOne = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const newObjectData: ObjectType = {
            customer_id: customer_id,
            adress: {
                street: street,
                nr: nr,
                plz: plz,
                city: city,
            },
        }
        addObject({ body: newObjectData, pageType: "object" })
    }

    return (
        <form onSubmit={handleNewOne}>
            <BaseModal
                title={"Objekt hinzufügen"}
                modalId={modalId}
                size
                bottomRow={
                    <div className="flex justify-end">
                        <button
                            className="btn btn-success"
                            type="submit"
                            disabled={customer_id === "" || !customer_id || street === "" || nr === "" || plz === "" || city === ""}>
                            Speichern
                        </button>
                    </div>
                }>
                {errorCustomer && <ErrorPage />}
                {isLoadingCustomer && <Loading />}
                {customer && (
                    <>
                        <BaseInputSelection
                            value={customer_id}
                            setId={setCustomerId}
                            label={"Kunde *"}
                            placeholder={"Kunde auswählen"}
                            data={customer.filter((item: { archived: boolean }) => item.archived !== true) ?? []}
                            classProps="pt-2"
                        />
                        <div className="flex flex-row gap-4 pt-2 w-full max-w-screen-lg">
                            <BaseInputField classProps="pt-2" width="w-96" label={"Straße *"} type={"text"} value={street} onChange={setstreet} />
                            <BaseInputField classProps="pt-2" width="w-40" label={"Hausnummer *"} type={"text"} value={nr} onChange={setnr} />
                        </div>
                        <div className="flex flex-row gap-4 w-full max-w-screen-lg pb-6">
                            <BaseInputField classProps="pt-2" width="w-40" label={"PLZ *"} type={"number"} value={plz} onChange={setplz} />
                            <BaseInputField classProps="pt-2" width="w-96" label={"Stadt *"} type={"text"} value={city} onChange={setcity} />
                        </div>
                    </>
                )}
            </BaseModal>
        </form>
    )
}
