import { motion } from "framer-motion"
import BaseInputField from "../../../../components/elements/BaseInputField"
import { OfferFilterModalType } from "../../types"

export default function OfferFilterModal({
    visible,
    filterRemove,
    filterStatus,
    filterChange,
    useFilter,
    setVisible,
    changeDateSince,
    changeDateTo,
    dateSince,
    dateTo,
    archived,
    setarchived,
}: OfferFilterModalType) {
    return (
        <motion.div
            initial={{
                x: "150%",
            }}
            animate={{
                x: visible ? 0 : "150%",
            }}
            transition={{
                type: "ease",
                duration: 0.3,
            }}
            className={`absolute -top-16 lg:top-20 right-0 lg:right-4 pt-8 h-[32rem] sm:h-fit w-full sm:w-96 rounded-default flex flex-col shadow-lg bg-white px-4 pb-4 z-[99] overflow-y-scroll`}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 absolute top-4 left-4 cursor-pointer"
                onClick={setVisible}>
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                />
            </svg>

            <div className="flex flex-col gap-1 pt-4">
                <h3 className="py-1 font-medium">
                    Nach Status filtern
                </h3>
                <div className="flex flex-row gap-6 pl-2">
                    <input
                        type="radio"
                        name="radio-group-offer-1"
                        className="radio"
                        value="option1"
                        checked={
                            filterStatus ===
                            "settled"
                        }
                        onChange={() =>
                            filterChange(
                                "settled"
                            )
                        }
                    />
                    <p>Angenommen</p>
                </div>
                <div className="flex flex-row gap-6 pl-2">
                    <input
                        type="radio"
                        name="radio-group-offer-1"
                        className="radio"
                        value="option2"
                        checked={
                            filterStatus ===
                            "unanswered"
                        }
                        onChange={() =>
                            filterChange(
                                "unanswered"
                            )
                        }
                    />
                    <p>Ausstehend</p>
                </div>
                <div className="flex flex-row gap-6 pl-2">
                    <input
                        type="radio"
                        name="radio-group-offer-1"
                        className="radio"
                        value="option3"
                        checked={
                            filterStatus ===
                            "rejected"
                        }
                        onChange={() =>
                            filterChange(
                                "rejected"
                            )
                        }
                    />
                    <p>Abgelehnt</p>
                </div>
            </div>
            <div className="flex flex-col gap-1 pt-4">
                <h3 className="py-1 font-medium">
                    Archiv anzeigen
                </h3>
                <div className="flex flex-row gap-6 pl-2">
                    <input
                        type="radio"
                        name="radio-group-offer-archive-1"
                        className="radio"
                        value="option1"
                        checked={
                            archived === "hidden"
                        }
                        onChange={() =>
                            setarchived("hidden")
                        }
                    />
                    <p>
                        Archivierte Angebote nicht
                        anzeigen
                    </p>
                </div>
                <div className="flex flex-row gap-6 pl-2">
                    <input
                        type="radio"
                        name="radio-group-offer-archive-1"
                        className="radio"
                        value="option2"
                        checked={
                            archived === "show"
                        }
                        onChange={() =>
                            setarchived("show")
                        }
                    />
                    <p>
                        Archivierte Angebote mit
                        anzeigen
                    </p>
                </div>
                <div className="flex flex-row gap-6 pl-2">
                    <input
                        type="radio"
                        name="radio-group-offer-archive-1"
                        className="radio"
                        value="option3"
                        checked={
                            archived === "only"
                        }
                        onChange={() =>
                            setarchived("only")
                        }
                    />
                    <p>
                        Nur Archivierte Angebote
                        anzeigen
                    </p>
                </div>
            </div>
            <div className="flex flex-col gap-1 pt-6">
                <h3 className="py-1 font-medium">
                    Nach Zeitraum filtern
                </h3>
                <BaseInputField
                    value={dateSince}
                    onChange={changeDateSince}
                    label={"von"}
                    type={"date"}
                />
                <BaseInputField
                    value={dateTo}
                    onChange={changeDateTo}
                    label={"bis"}
                    type={"date"}
                />
            </div>
            <div className="flex flex-row items-center justify-between mt-6">
                <p
                    className="underline text-sm cursor-pointer"
                    onClick={filterRemove}>
                    Zurücksetzen
                </p>
                <button
                    className="btn btn-primary btn-outline"
                    onClick={useFilter}>
                    Anwenden
                </button>
            </div>
        </motion.div>
    )
}
