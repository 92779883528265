import BaseModal from "../../../../../components/layout/BaseModal"
import { MailReportType, PR } from "../../../../../data/Types"
import dayjs from "dayjs"
import dayjsBusinessDays from "dayjs-business-days2"
import BaseMailPreviewDataRow from "../../../../../components/elements/BaseMailPreviewDataRow"

dayjs.extend(dayjsBusinessDays)

type ReminderModalType = {
    modalId: number
    payment?: PR
    maxReminder: number
    intervalReminder: number
    handleSetReminder: () => void
    setMailPreviewContent: (e: string) => void
    setResendUri: (e: string) => void
}

export default function ReminderModal(props: ReminderModalType) {
    console.log(props.payment)
    console.log(props.payment?.reminders)

    return (
        <BaseModal size title="Zahlung mahnen" modalId={props.modalId}>
            <div className="flex flex-row justify-between text-base px-2 pt-2 font-semibold w-[32rem]">
                <p>Ausstellungsdatum der Rechnung:</p>
                <p>{dayjs(props.payment?.invoice_date).format("DD.MM.YYYY")}</p>
            </div>
            <div className="flex flex-row justify-between text-base px-2 font-semibold w-[32rem]">
                <p>Zahlung fällig am:</p>
                <p>{dayjs(props.payment?.due_date).format("DD.MM.YYYY")}</p>
            </div>
            <div className="flex flex-row justify-between text-base px-2 font-semibold w-[32rem]">
                <p>Tage überfällig:</p>
                {dayjs().diff(props.payment?.due_date, "day") <= 0 ? (
                    <p className="text-gray-400">0 Tage</p>
                ) : (
                    <p className="text-red-500">{dayjs().diff(props.payment?.due_date, "day")} Tage</p>
                )}
            </div>
            <div className="flex flex-row justify-between text-base pb-2 px-2 font-semibold w-[32rem]">
                <p>Betrag:</p>
                <p>{props.payment?.expected_payment.toFixed(2).toString().replace(".", ",")} €</p>
            </div>
            {Array.from({ length: props.maxReminder }).map((_, i) => (
                <ReminderRow
                    key={i}
                    mailReports={props.payment?.reminders[i]?.mailReports}
                    nr={i + 1}
                    due={props.payment?.due_date}
                    days={props.intervalReminder * (i + 1)}
                    alreadyReminded={i + 1 === props.payment?.reminders[i]?.reminderCount}
                    reminderDone={!(i === props.payment?.reminders?.length)}
                    sendReminderManual={() => props.handleSetReminder()}
                    setMailPreviewContent={props.setMailPreviewContent}
                    setResendUri={props.setResendUri}
                />
            ))}
        </BaseModal>
    )
}

type reminderRowType = {
    nr: 1 | 2 | 3 | number
    due: Date | undefined
    days: number
    alreadyReminded: boolean
    reminderDone: boolean
    mailReports: MailReportType[]
    sendReminderManual: () => void
    setMailPreviewContent: (e: string) => void
    setResendUri: (e: string) => void
}

function ReminderRow(props: reminderRowType) {
    return (
        <div className="flex flex-col">
            <div className="flex flex-row p-4 border-t w-full justify-between items-center">
                <p>{props.nr} Mahnung</p>
                <p>Fällig am: {dayjs(props?.due).businessDaysAdd(props.days).format("DD.MM.YYYY")}</p>
                {props.alreadyReminded ? (
                    <p className="font-bold text-xs uppercase text-gray-300">Mahnung gesendet</p>
                ) : (
                    <button disabled={props.reminderDone} className="btn btn-sm btn-primary" onClick={props.sendReminderManual}>
                        Manuell Mahnen
                    </button>
                )}
            </div>
            {props.mailReports &&
                props.mailReports?.map((item, key) => (
                    <BaseMailPreviewDataRow
                        key={key}
                        sidePanelConId={4}
                        index={props.mailReports.indexOf(item) + 1}
                        item={item}
                        setResendUri={props.setResendUri}
                        setMailPreviewContent={props.setMailPreviewContent}
                    />
                ))}
        </div>
    )
}
