import { Select } from "antd"
import { useMemo } from "react"
import { BaseSelectionType } from "../Types"

export default function BaseSelection(props: BaseSelectionType) {
    // Transforms the input array in the needed format
    const arrayData = useMemo(() => {
        return props.data.map((obj) => ({
            value: obj,
            label: obj,
        }))
    }, [props.data])

    // change & set data
    const onChange = (value: string) => {
        props.setId(value)
    }

    return (
        <div className={`flex flex-col w-full z-10 ${props.classProps}`}>
            <label className="text-sm pl-2 pb-1">{props.label}</label>
            <Select
                style={{ height: "3.5rem" }}
                value={props.value ?? undefined}
                defaultValue={props.defaultValue ?? undefined}
                disabled={arrayData.length === 0 || arrayData === undefined || props.disabled}
                placeholder={props.placeholder}
                className="w-full"
                onChange={onChange}
                options={arrayData}
            />
        </div>
    )
}
