import { motion } from "framer-motion"
import { useState } from "react"
import { RowElementType } from "../../types/Types"

export function RowElement ({ visible, standingOrder }: RowElementType) {

    const [showButtons, setshowButtons] = useState(false)

    return (
        <tr onClick={() => setshowButtons(!showButtons)} className="cursor-pointer z-0 border-b border-gray-200 text-sm relative overflow-hidden">
            <td className=""><p className="line-clamp-2 py-1">Eine neue Tolle Straße 16, 012345 Dresden</p></td>
            <td className=""><p className="text-start">11.11.2023</p></td>
            { !standingOrder && <td className=""><p className="text-start">In Bearbeitung</p></td> }
            <td className=""><p className="line-clamp-2">Hier Hier Hier Hier Hier HierHierHierHierHierHierHierHierHier muss die Hecke kreuz und quer geschnitten werden</p></td>
            { visible &&
                <>
                    <td className=""><p className="line-clamp-2">Hier Hier Hier Hier Hier HierHierHierHierHierHierHierHierHier muss die Hecke kreuz und quer geschnitten werden</p></td>
                    <td className=""><p className="line-clamp-2">Mitarbeiter 1 <br />Mitarbeiter 2 <br />Mitarbeiter 3</p></td>
                </>
            }

            <motion.div 
            initial={{
                x: '100%'
            }}
            animate={{
                x: showButtons ? 0 : '100%'
            }}
            transition={{
                duration: 0.2,
                type: 'ease'
            }}
            className="absolute top-0 right-0 h-full w-fit z-10 flex py-2">
                <button onClick={() => alert('info')} className="w-14 rounded-l-default text-white z-10 flex justify-center items-center h-full bg-blue-400">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7"><path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" /></svg>
                </button>
                <button onClick={() => alert('mail')} className="w-14 text-white z-10 flex justify-center items-center h-full bg-gray-400">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7"><path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" /></svg>
                </button>
                <button onClick={() => alert('delete')} className="w-14 text-white z-10 flex justify-center items-center h-full bg-red-400">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7"><path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" /></svg>
                </button>
                <button className="w-14 text-black z-10 flex justify-center items-center h-full bg-white">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7"><path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" /></svg>
                </button>
            </motion.div>
        </tr>
    )
}