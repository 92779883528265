import { useState } from "react";
import BaseModal from "../../../../components/layout/BaseModal";
import ModalTaskScreen1 from "./ModalTaskScreen1";
import ModalTaskScreen2 from "./ModalTaskScreen2";
import ModalTaskScreen3 from "./ModalTaskScreen3";

export default function AddTaskModal() {

    const [showEmployees, setshowEmployees] = useState(false)
    const [screen2, setscreen2] = useState(false)
    const [screen3, setscreen3] = useState(false)

    return (
        <BaseModal title="Neue Aufgabe hinzufügen" modalId={1} bottomRow={
            <div className="flex justify-between items-center">
                <button disabled={!screen2} className="underline text-sm" onClick={() => {
                    if (screen2 && !screen3) {
                        setscreen2(false);
                    }
                    else if (screen2 && screen3) {
                        setscreen3(false)    
                    }
                }}>zurück</button>
                {( screen3 && screen2 ) ?
                    <button className="btn btn-success">Speichern</button>
                    :
                    <button className="btn bg-blue-300 border-none hover:bg-blue-400" onClick={() => {
                        if (!screen2 && !screen3) {
                            setshowEmployees(false);
                            setscreen2(true);
                        }
                        else if (screen2 && !screen3) {
                            setscreen3(true)    
                        }
                    }}>Wesiter</button>
                }
            </div>
        }>
            <div className="overflow-hidden relative h-full w-full">
                {/* <ModalTaskScreen1 visible={true} objectData={objectData} showEmployees={showEmployees} setshowEmployees={() => setshowEmployees(!showEmployees)}/> */}
                <ModalTaskScreen2 visible={screen2} />
                <ModalTaskScreen3 visible={screen3} />
            </div>
            {/* <PopUpObjectList visible={showEmployees} setVisible={() => setshowEmployees(!showEmployees)} elementType='employee' elements={employeeData} /> */}
        </BaseModal>
    );
}
