import { useContext } from "react"
import { ActionMessageContext } from "../contexts/ActionMessageContext"
import { motion } from "framer-motion"

export default function BaseAlertComponent() {
    const alert = useContext(ActionMessageContext)

    return (
        <motion.div
            initial={{
                y: "200%",
                opacity: 0,
            }}
            animate={
                !alert?.alert.visible
                    ? {
                          y: "200%",
                          opacity: 0,
                      }
                    : {
                          y: 0,
                          opacity: 1,
                      }
            }
            transition={{
                duration: 1,
            }}
            className={`alert ${alert?.alert.err ? "alert-error" : "alert-success"} absolute bottom-8 right-8 z-[10000]`}>
            {alert?.alert.err ? (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                    />
                </svg>
            ) : (
                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            )}
            {!alert?.alert.err && alert?.alert.type === "add" && <span>{alert?.alert.information} erfolgreich hinzugefügt</span>}
            {!alert?.alert.err && alert?.alert.type === "resend" && <span>{alert?.alert.information} erfolgreich neu versandt.</span>}
            {!alert?.alert.err && alert?.alert.type === "update" && <span>{alert?.alert.information} erfolgreich aktualisiert</span>}
            {!alert?.alert.err && alert?.alert.type === "delete" && <span>{alert?.alert.information} erfolgreich gelöscht</span>}
            {!alert?.alert.err && alert?.alert.type === "download" && <span>Download erfolgreich</span>}
            {alert?.alert.err && alert?.alert.type === "add" && <span>Fehler bei {alert?.alert.information} hinzufügen</span>}
            {alert?.alert.err && alert?.alert.type === "update" && <span>Fehler bei {alert?.alert.information} aktualisieren</span>}
            {alert?.alert.err && alert?.alert.type === "delete" && <span>Fehler bei {alert?.alert.information} löschen</span>}
            {alert?.alert.err && alert?.alert.type === "resend" && <span>Fehler bei {alert?.alert.information}</span>}
            {alert?.alert.err && alert?.alert.type === "download" && <span>Fehler beim Download von {alert?.alert.information}</span>}
        </motion.div>
    )
}
