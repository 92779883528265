import { useParams } from "react-router-dom"
import BaseHeadBanner from "../../../../components/elements/BaseHeadBanner"
import BasePage from "../../../../components/layout/BasePage"
import ModalCheckAction from "../../../../components/elements/ModalCheckAction"
import { useContext, useEffect, useMemo, useState } from "react"
import { ModalContext } from "../../../../components/contexts/ModalContext"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { getSingleOfferData } from "../../api/apiCalls"
import Loading from "../../../../components/common/Loading"
import ErrorPage from "../../../404/ErrorPage"
import BillFromOfferModal from "../../components/billFromOffer/BillFromOfferModal"
import { useArchiveOfferMutation, useUpdateStatusOfferMutation } from "../../api/services"
import { useDownloadDocumentMutation } from "../../../api/services"
import ObjectDetailButtonRow from "./components/OfferDetailButtonRow"
import { getPdfFileToPreview } from "../../../api/apiCalls"
import OfferDetailHead from "./components/OfferDetailHead"
import OfferDetailOfferTable from "./components/OfferDetailOfferTable"
import OfferDetailFiles from "./components/OfferDetailFiles"
import BasePreview from "../../../../components/elements/BasePreview"
import { useResendMailMutation } from "../../../../services/api/services"
import { API } from "../../../../api/constants"

export default function OfferDetailPage() {
    const { id } = useParams<string>()
    const { data, error, isLoading } = useQuery({
        queryKey: ["singleOffer" + id],
        queryFn: () => getSingleOfferData(id || ""),
    })

    const offerDownloadUrl = useMemo(() => {
        return error && !isLoading ? undefined : API + `/offer/${data?._id}/download?s3Key=${data?.s3Ref?.s3Key}`
    }, [data, error, isLoading])

    const modalCon = useContext(ModalContext)

    // UPDATE REQUESTS - Offer Status & Archived
    const queryClient = useQueryClient()
    const [status, setstatus] = useState<"unanswered" | "settled" | "rejected" | string>(data?.status)
    useEffect(() => {
        if (data?.status) {
            setstatus(data?.status)
        }
    }, [data?.status])
    const { mutate: updateStatusOffer } = useUpdateStatusOfferMutation(queryClient)
    const handleChangeStatus = () => {
        updateStatusOffer({
            id: data?.id ?? id,
            body: { status: status },
        })
    }

    const { mutate: archiveOffer } = useArchiveOfferMutation(queryClient, modalCon, 0)
    const handleArchiveOffer = () => {
        archiveOffer({
            id: data?._id ?? id,
            body: { archived: !data?.archived },
        })
    }

    // download offer file - or offer attachments
    const { mutate: downloadFile } = useDownloadDocumentMutation()
    const handleDownload = (fileData?: { s3Key: string; filename: string }) => {
        if (fileData) {
            downloadFile({ id: data._id, s3Key: fileData.s3Key, filetype: "offer", filename: fileData.filename })
        } else {
            downloadFile({ id: data._id, s3Key: data.s3Ref.s3Key, filetype: "offer", filename: "Angebot " + data.nr + ".pdf" })
        }
    }

    const { mutate: resendMailFunction } = useResendMailMutation()
    return (
        <BasePage>
            <BaseHeadBanner title={"Angebot " + data?.nr} />
            {isLoading && <Loading />}
            {error && <ErrorPage />}
            {data && !isLoading && !error && (
                <>
                    <OfferDetailHead data={data} />
                    <OfferDetailOfferTable data={data} />
                    <OfferDetailFiles data={data} handleDownload={handleDownload} />
                    <ObjectDetailButtonRow
                        status={status}
                        setstatus={setstatus}
                        statusCopy={data.status}
                        handleChangeStatus={handleChangeStatus}
                        createdBillCount={data.created_bill.length}
                        handleDownload={handleDownload}
                    />
                </>
            )}
            <ModalCheckAction
                modalId={0}
                headline={data?.archived ? "Archivieren Rückgängig machen?" : "Wirklich Archivieren?"}
                text={
                    data?.archived
                        ? "Sind Sie sicher, dass Sie das Angebot nicht mehr archivieren wollen?"
                        : "Sind Sie sich sicher, dass Sie das Angebot archivieren wollen?"
                }
                buttonText={data?.archived ? "Rückgängig" : "Archivieren"}
                buttonColor={"bg-blue-300 hover:bg-blue-400 border-none"}
                func={handleArchiveOffer}
            />
            <BillFromOfferModal
                documents={data?.documents}
                customer={{
                    _id: data?.customer_id?._id,
                    name: data?.customer_id?.customer.name,
                }}
                object={{
                    _id: data?.object_id?._id,
                    adress:
                        data?.object_id?.adress?.street +
                        " " +
                        data?.object_id?.adress?.nr +
                        ", " +
                        data?.object_id?.adress?.city +
                        " " +
                        data?.object_id?.adress?.plz,
                }}
                positions={data?.positions}
                offer={id ?? data?.id}
            />
            <BasePreview title={"Angebotsvorschau"} sidePanelId={3} previewType={"document"} content={offerDownloadUrl} />
            <BasePreview
                title={"Mailvorschau"}
                sidePanelId={4}
                previewType={"mail"}
                content={data?.mailReports[0]?.mailContent}
                resendFunction={status !== "rejected" ? () => resendMailFunction(data?.mailReports[0].resendUri) : undefined}
            />
        </BasePage>
    )
}
