import { motion } from "framer-motion";
import { TaskDashboardCardType } from "../../types/Types";
import { RowElement } from "./TableRowElement";

export default function TaskDashboardCard({ headline, data, width, standingOrder }: TaskDashboardCardType) {
    return (
        <motion.div className={`${width ? width : 'w-full'} overflow-hidden min-h-[24rem] h-full bg-white shadow-lg rounded-default p-4 flex flex-col`}>
            <div className="flex flex-row justify-between border-black border-b-2 pb-2">
                <h3 className="pl-2 uppercase tracking-tight font-semibold">{headline}</h3>
                <p title='Vollansicht'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer" onClick={() => alert('Navigte to Page')}><path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" /></svg></p>
            </div>
            <div className="overflow-x-hidden mt-2">
                <table className="table table-sm table-pin-rows" >
                    <thead>
                        <tr className="h-10 border-none">
                            <th className="w-40 ">Adresse</th>
                            <th className="w-24 ">Fällig</th>
                            {
                                !standingOrder && <th className="w-32 ">Status</th>
                            }
                            <th className="">Auftrag</th>
                            {
                                !width &&
                                <>
                                    <th className="">Hinweise</th>
                                    <th className="w-40">Zuständig</th>
                                </>
                            }
                        </tr>
                    </thead>
                    <tbody className="overflow-y-scroll">
                        <RowElement visible={width ? false : true} standingOrder={standingOrder} />
                        <RowElement visible={width ? false : true} standingOrder={standingOrder} />
                        <RowElement visible={width ? false : true} standingOrder={standingOrder} />
                        <RowElement visible={width ? false : true} standingOrder={standingOrder} />
                        <RowElement visible={width ? false : true} standingOrder={standingOrder} />
                        <RowElement visible={width ? false : true} standingOrder={standingOrder} />
                        <RowElement visible={width ? false : true} standingOrder={standingOrder} />
                        <RowElement visible={width ? false : true} standingOrder={standingOrder} />
                        <RowElement visible={width ? false : true} standingOrder={standingOrder} />
                    </tbody> 

                </table>
            </div>
        </motion.div>
    )
}