import { API } from "../../../api/constants"
import { OfferTextTemplate } from "../../../data/Types"
import { authenticatedAxiosInstance } from "../../../auth/AuthenticatedAxios"

export const getBillByObject = (id: string) => authenticatedAxiosInstance.get(API + "/bill/get/object/" + id).then((res) => res.data)

export const getSingleBillData = (id: string) => authenticatedAxiosInstance.get(API + "/bill/get/" + id).then((res) => res.data)

export const getOfferByObject = (id: string) => authenticatedAxiosInstance.get(API + "/offer/get/object/" + id).then((res) => res.data)

export const getSingleOfferData = (id: string) => authenticatedAxiosInstance.get(API + "/offer/get/" + id).then((res) => res.data)

export const getOfferTextTemplate = () => authenticatedAxiosInstance.get(API + "/offer/template/text/get/all").then((res) => res.data)

export const postNewOffer = (body: any) =>
    authenticatedAxiosInstance.post(API + "/offer/add", body, { headers: { "Content-Type": "multipart/form-data" } }).then((res) => res.data)

export const postTextTemplates = (body: OfferTextTemplate[]) =>
    authenticatedAxiosInstance.post(API + "/offer/template/text/add", body).then((res) => res.data)

export const statusOffer = (data: { id: string; body: { status: string } }) =>
    authenticatedAxiosInstance.patch(API + "/offer/status/update/" + data.id, data.body).then((res) => res.data)

export const archiveOffer = (data: { id: string; body: { archived: boolean } }) =>
    authenticatedAxiosInstance.patch(API + "/offer/archive/update/" + data.id, data.body).then((res) => res.data)

export const postNewBill = (body: any) =>
    authenticatedAxiosInstance.post(API + "/bill/add", body, { headers: { "Content-Type": "multipart/form-data" } }).then((res) => res.data)

export const archiveBill = (data: { id: string; body: { archived: boolean } }) =>
    authenticatedAxiosInstance.patch(API + "/bill/archive/update/" + data.id, data.body).then((res) => res.data)

export const cancelBill = (data: { id: string; body: { canceled: boolean } }) =>
    authenticatedAxiosInstance.patch(API + "/bill/cancel/update/" + data.id, data.body).then((res) => res.data)

export const solvePaymentEscalation = (id: String) =>
    authenticatedAxiosInstance.patch(API + "/paymentrequirements/solve-escalation/" + id).then((res) => res.data)
