import { useEffect, useState } from "react"
import { PaymentChart } from "./PaymentChart"
import dayjs from "dayjs"
import {
    getPaymentsOver12MonthsData,
    getTransactionsOver12MonthsData,
} from "../../api/apiCalls"
import { useQuery } from "@tanstack/react-query"
import Loading from "../../../../components/common/Loading"
import ErrorPage from "../../../404/ErrorPage"

const singleLabels = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
]
const labels = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
]

export default function Charts() {
    const {
        data: paymentAmountData,
        isLoading,
        error,
    } = useQuery({
        queryKey: ["paymentAmountData"],
        queryFn: getPaymentsOver12MonthsData,
    })
    const {
        data: bakingAmountData,
        isLoading: bankingLoading,
        error: bankingError,
    } = useQuery({
        queryKey: ["bankingAmountData"],
        queryFn: getTransactionsOver12MonthsData,
    })
    const [label, setlabel] =
        useState<string[]>(labels)
    const [month, setmonth] = useState<number>(
        dayjs().month()
    )
    const [chart, setChart] = useState<any>(null)
    const data = [
        {
            label: "erhaltene Einnahmen",
            data: [],
            backgroundColor: [
                "rgba(255, 99, 132, 0.2)",
            ],
            borderColor: [
                "rgba(255, 99, 132, 1)",
            ],
            borderWidth: 1,
        },
        {
            label: "erwartete Einnahmen",
            data: [],
            backgroundColor: [
                "rgba(2, 99, 132, 0.2)",
            ],
            borderColor: ["rgba(2, 99, 132, 1)"],
            borderWidth: 1,
        },
    ]

    const resetDatasets = (slice: number) => {
        const updatedDatasets = data.map(
            (dataset) => ({
                ...dataset,
                data:
                    dataset.label ===
                    "erwartete Einnahmen"
                        ? paymentAmountData.slice(
                              slice
                          )
                        : bakingAmountData.slice(
                              slice
                          ),
            })
        )
        return updatedDatasets
    }

    // Funktion zum Aktualisieren der Labels
    const updateLabels = (
        newLabels: number[]
    ) => {
        if (chart && newLabels.length !== 0) {
            chart.data.datasets = resetDatasets(
                -newLabels.length
            )
            chart.data.labels = newLabels.map(
                (item) => label[item]
            )
            chart.update()
        } else if (
            chart &&
            newLabels.length === 0
        ) {
            chart.data.datasets = resetDatasets(0)
            chart.data.labels = label
            chart.update()
        }
    }

    useEffect(() => {
        if (month && labels === label) {
            let firstL = labels.slice(
                labels.indexOf(
                    singleLabels[month]
                ) + 1
            )
            firstL.splice(
                firstL.indexOf(
                    singleLabels[month]
                ) + 1
            )
            setlabel(firstL)
        }
        if (label !== labels && chart) {
            updateLabels([10, 11])
        }
    }, [chart, month, label])

    return (
        <>
            {(isLoading || bankingLoading) && (
                <Loading />
            )}
            {(error || bankingError) && (
                <ErrorPage />
            )}
            {paymentAmountData &&
                !error &&
                !isLoading &&
                !bankingError &&
                bakingAmountData &&
                !bankingLoading && (
                    <section className="w-full max-w-[50%] bg-white rounded-default shadow-lg grow h-[85vh] mt-4 p-4">
                        <div className="border-b pb-2 flex justify-between">
                            <h3 className="text-xl font-semibold">
                                Statisitk
                            </h3>
                            <div className="flex gap-2">
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() =>
                                        updateLabels(
                                            [11]
                                        )
                                    }>
                                    Aktueller
                                    Monat
                                </button>
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() =>
                                        updateLabels(
                                            [
                                                9,
                                                10,
                                                11,
                                            ]
                                        )
                                    }>
                                    3 Monate
                                </button>
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() =>
                                        updateLabels(
                                            [
                                                6,
                                                7,
                                                8,
                                                9,
                                                10,
                                                11,
                                            ]
                                        )
                                    }>
                                    6 Monate
                                </button>
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() =>
                                        updateLabels(
                                            []
                                        )
                                    }>
                                    1 Jahr
                                </button>
                            </div>
                        </div>
                        {label !== labels && (
                            <PaymentChart
                                setChart={
                                    setChart
                                }
                                labels={label}
                                data={data}
                            />
                        )}
                    </section>
                )}
        </>
    )
}
