import { BaseInputFieldType } from "../../pages/Buchhaltung/types"
import { preventKeyDown } from "../../services/functions"
import { Input } from "antd"

export default function BaseInputField(
    props: BaseInputFieldType
) {
    return (
        <div
            className={`flex flex-col ${
                props.width
                    ? props.width
                    : "w-full"
            } ${props.classProps}`}>
            <label className="text-sm pl-2 pb-1">
                {props.label}
            </label>
            <Input
                onKeyDown={
                    props.prevent
                        ? preventKeyDown
                        : () => {}
                }
                required={props.required ?? true}
                onChange={(e) =>
                    props.onChange(e.target.value)
                }
                prefix={<></>}
                status={props.status ?? undefined}
                type={props.type}
                value={props.value}
                disabled={props.disabled}
                placeholder={props.placeholder}
                rootClassName={
                    "border h-14 rounded-default w-full"
                }
            />
        </div>
    )
}
