import { useContext } from "react"
import { Roles } from "../../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../../../components/authentication/RenderWhenAuthorized"
import { ModalContext } from "../../../components/contexts/ModalContext"
import BaseInputField from "../../../components/elements/BaseInputField"
import { Employee } from "../types/Types"
import BaseSelection from "../../../components/elements/BaseSelection"
import BaseInputArea from "../../../components/elements/BaseInputArea"
import { DocumentDataType } from "../../../components/Types"
import BaseDocumentElement from "../../../components/elements/BaseDocumentElement"
import { checkForEmptyValues, compareValues, validateEmail } from "../../../services/functions"
import { useKeycloak } from "@react-keycloak/web"
import { SidePanelContext } from "../../../components/contexts/SidePanelContext"

type EmployeePartRightType = {
    rbacGroup: string
    setrbacGroup: (e: string) => void
    setselectedS3Key: (e: string) => void
    selectedEmployee: Employee
    selectedEmployeeCopy?: Employee
    setselectedEmployee: (e: any) => void // TODO any
    handleUpdateEmployee: () => void
    setfileToDelete: (e: string) => void
    handleDownload: (s3Key: string, filename: string) => void
    kcData: any // TODO any
}

export default function EmployePartRight(props: EmployeePartRightType) {
    const modalCon = useContext(ModalContext)
    const sidePanelCon = useContext(SidePanelContext)
    const { keycloak } = useKeycloak()

    return (
        <div className="flex flex-col h-full pb-24 sm:pb-0 sm:max-h-[82vh] w-full sm:w-1/2 bg-white rounded-default shadow-lg p-4 overflow-y-scroll">
            <div className="flex justify-between items-end">
                <h3 className="font-bold text-2xl pt-2">Persönliche Informationen</h3>
                <RenderWhenAuthorized requiresAll={[Roles.mitarbeiter_read_write]}>
                    <button className="btn btn-ghost btn-sm" onClick={() => modalCon?.trigger(7)}>
                        Passwort Zurücksetzen
                    </button>
                </RenderWhenAuthorized>
            </div>
            <div className="flex flex-col overflow-y-scroll">
                <div className="w-full border-b my-3" />
                <div className="flex flex-col md:flex-row gap-2 md:gap-4">
                    <BaseInputField
                        classProps="pt-2"
                        label={"Vorname *"}
                        type={"text"}
                        value={props.selectedEmployee?.first_name}
                        onChange={(newFirstName: string) => {
                            props.setselectedEmployee((prevState: any) => ({
                                ...prevState,
                                first_name: newFirstName,
                            }))
                        }}
                    />
                    <BaseInputField
                        classProps="pt-2"
                        label={"Nachname *"}
                        type={"text"}
                        value={props.selectedEmployee?.last_name}
                        onChange={(newItem: string) => {
                            props.setselectedEmployee((prevState: any) => ({
                                ...prevState,
                                last_name: newItem,
                            }))
                        }}
                    />
                </div>
                <div className="flex flex-col md:flex-row gap-2 md:gap-4">
                    <BaseInputField
                        classProps="pt-2"
                        label={"Geburtstag *"}
                        type={"date"}
                        value={props.selectedEmployee?.geb?.split("T")[0]}
                        onChange={(newItem: string) => {
                            props.setselectedEmployee((prevState: any) => ({
                                ...prevState,
                                geb: newItem,
                            }))
                        }}
                    />
                    <BaseSelection
                        disabled={!keycloak.hasRealmRole(Roles.mitarbeiter_manage_permissions)}
                        classProps="pt-2"
                        label={"Position *"}
                        setId={(newItem: string) => {
                            props.setrbacGroup(newItem)
                        }}
                        placeholder={"Position auswählen"}
                        data={props.kcData.rbac.map((item: { id: string; name: string }) => item.name)}
                        value={props.rbacGroup}
                    />
                </div>
                <div className="flex flex-col md:flex-row gap-2 md:gap-4">
                    <BaseInputField
                        classProps="pt-2"
                        label={"Vertragsbeginn *"}
                        type={"date"}
                        value={props.selectedEmployee?.contractStart ?? ""}
                        onChange={(newItem: string) => {
                            props.setselectedEmployee((prevState: any) => ({
                                ...prevState,
                                contractStart: newItem,
                            }))
                        }}
                    />
                    <BaseInputField
                        classProps="pt-2"
                        label={"Vertragsende"}
                        type={"date"}
                        value={props.selectedEmployee?.contractEnd ?? ""}
                        onChange={(newItem: string) => {
                            props.setselectedEmployee((prevState: any) => ({
                                ...prevState,
                                contractEnd: newItem,
                            }))
                        }}
                    />
                </div>
                <div className="flex flex-col md:flex-row gap-2 md:gap-4">
                    <BaseInputField
                        classProps="pt-2"
                        label={"E-Mail *"}
                        type={"email"}
                        status={!validateEmail(props.selectedEmployee.email) ? "error" : undefined}
                        value={props.selectedEmployee?.email ?? ""}
                        onChange={(newItem: string) => {
                            props.setselectedEmployee((prevState: any) => ({
                                ...prevState,
                                email: newItem,
                            }))
                        }}
                    />
                    <div className="flex flex-row lg:justify-evenly gap-4 md:py-0 w-full items-center">
                        <div className="form-control pt-8">
                            <label className="label cursor-pointer">
                                <input
                                    type="checkbox"
                                    checked={props.selectedEmployee?.vehicle}
                                    onChange={() => {
                                        props.setselectedEmployee((prevState: any) => ({
                                            ...prevState,
                                            vehicle: !props.selectedEmployee?.vehicle,
                                        }))
                                    }}
                                    className="checkbox"
                                />
                                <span className="label-text pl-4">Dienstwagen</span>
                            </label>
                        </div>
                        <div className="form-control pt-8">
                            <label className="label cursor-pointer">
                                <input
                                    type="checkbox"
                                    checked={props.selectedEmployee?.trailperiod}
                                    className="checkbox"
                                    onChange={() => {
                                        props.setselectedEmployee((prevState: any) => ({
                                            ...prevState,
                                            trailperiod: !props.selectedEmployee?.trailperiod,
                                        }))
                                    }}
                                />
                                <span className="label-text pl-4">Probezeit</span>
                            </label>
                        </div>
                    </div>
                </div>
                <BaseSelection
                    classProps="pt-2"
                    label={"Kostenstelle *"}
                    setId={(newItem: any) => {
                        props.setselectedEmployee((prevState: any) => ({
                            ...prevState,
                            kostenstelle: newItem,
                        }))
                    }}
                    placeholder={"Kostenstelle auswählen"}
                    data={props.kcData.ou.map((item: { id: string; name: string }) => item.name)}
                    value={props.selectedEmployee?.kostenstelle}
                />
                <BaseInputArea
                    label={"Bemerkung"}
                    classProps="pt-2"
                    value={props.selectedEmployee?.notes ?? ""}
                    onChange={(newItem: string) => {
                        props.setselectedEmployee((prevState: any) => ({
                            ...prevState,
                            notes: newItem,
                        }))
                    }}
                />
                <div className="flex flex-col pt-2">
                    <label className="text-sm pl-2 pb-1">Dokumente</label>
                    {props.selectedEmployee?.documents?.map((item: DocumentDataType, index: number) => (
                        <BaseDocumentElement
                            key={index}
                            title={item.title}
                            timestamp={item.timestamp}
                            roles={[Roles.mitarbeiter_read_write]}
                            downloadFunc={() => props.handleDownload(item.s3Key, item.title)}
                            deleteFunc={() => {
                                props.setfileToDelete(item.s3Key)
                                modalCon?.trigger(6)
                            }}
                            showPreviewFunc={() => {
                                console.log('triggert')
                                sidePanelCon?.trigger(8)
                                props.setselectedS3Key(item.s3Key)
                            }}
                        />
                    ))}
                    {props.selectedEmployee?.documents?.length === 0 && <p className="pl-4 text-xs">Keine Dokumente vorhanden</p>}

                    <RenderWhenAuthorized requiresAll={[Roles.mitarbeiter_read_write]}>
                        <button
                            className="hidden md:block btn btn-sm bg-base-100 shadow-lg border-none hover:bg-white/40 mt-2 mb-4 mr-4 self-end"
                            onClick={() => modalCon?.trigger(2)}>
                            Dokument hinzufügen
                        </button>
                    </RenderWhenAuthorized>
                </div>
            </div>
            <RenderWhenAuthorized requiresAll={[Roles.mitarbeiter_read_write]}>
                <div className="grow flex items-end justify-end pb-4">
                    <button
                        disabled={
                            checkForEmptyValues([
                                props.selectedEmployee?.first_name,
                                props.selectedEmployee?.last_name,
                                props.selectedEmployee?.geb,
                                props.selectedEmployee?.contractStart,
                                props.selectedEmployee?.email,
                                props.selectedEmployee?.kostenstelle,
                            ]) ||
                            !validateEmail(props.selectedEmployee.email) ||
                            !compareValues([
                                {
                                    v1: props.selectedEmployee?.kostenstelle,
                                    v2: props.selectedEmployeeCopy?.kostenstelle,
                                },
                                {
                                    v1: props.selectedEmployee?.trailperiod,
                                    v2: props.selectedEmployeeCopy?.trailperiod,
                                },
                                {
                                    v1: props.selectedEmployee?.vehicle,
                                    v2: props.selectedEmployeeCopy?.vehicle,
                                },
                                {
                                    v1: props.selectedEmployee?.first_name,
                                    v2: props.selectedEmployeeCopy?.first_name,
                                },
                                {
                                    v1: props.selectedEmployee?.position,
                                    v2: props.rbacGroup,
                                },
                                {
                                    v1: props.selectedEmployee?.email,
                                    v2: props.selectedEmployeeCopy?.email,
                                },
                                {
                                    v1: props.selectedEmployee?.last_name,
                                    v2: props.selectedEmployeeCopy?.last_name,
                                },
                                {
                                    v1: props.selectedEmployee?.geb,
                                    v2: props.selectedEmployeeCopy?.geb,
                                },
                                {
                                    v1: props.selectedEmployee?.contractStart,
                                    v2: props.selectedEmployeeCopy?.contractStart,
                                },
                                {
                                    v1: props.selectedEmployee?.contractEnd,
                                    v2: props.selectedEmployeeCopy?.contractEnd,
                                },
                                {
                                    v1: props.selectedEmployee?.notes,
                                    v2: props.selectedEmployeeCopy?.notes,
                                },
                            ])
                        }
                        className="btn btn-success"
                        onClick={props.handleUpdateEmployee}>
                        Speichern
                    </button>
                </div>
            </RenderWhenAuthorized>
        </div>
    )
}
