import { API } from "../../api/constants"
import { authenticatedAxiosInstance } from "../../auth/AuthenticatedAxios"
import { ArchiveDataParams, DeleteDataParams, PostNewDataParams } from "./Types/Types"

// NOTE - general api requests, which are used from different pages

// get paginated data - bill / offer / paymentrequirements / object / customer / employee
export const getPaginatedData = (pageType: string, page: number, limit: number) =>
    authenticatedAxiosInstance.get(API + `/${pageType}/paginate?page=${page}&limit=${limit}&pagination=true`).then((res) => res.data)

// add data - object / customer / employee
export const postNewDataGeneral = (data: PostNewDataParams) =>
    authenticatedAxiosInstance.post(API + `/${data.pageType}/add`, data.body).then((res) => res.data)

// delete data -
export const deleteDataGeneral = (data: DeleteDataParams) =>
    authenticatedAxiosInstance.delete(API + `/${data.pageType}/delete/` + data.id).then((res) => res.data)

// update archive data - object / customer / employee
export const archiveDataGeneral = (data: ArchiveDataParams) =>
    authenticatedAxiosInstance.patch(API + `/${data.pageType}/archive/update/` + data.id, data.body).then((res) => res.data)

// get document from any type
export const getDocumentFromAnyType = (data: { id: string; s3Key: string; filetype: string }) =>
    authenticatedAxiosInstance
        .get(API + `/${data.filetype}/${data.id}/download?s3Key=${data.s3Key}`, { responseType: "blob" })
        .then((res) => res.data)

// pdf preview
export const getPdfFileToPreview = (data: { s3Key: string }) =>
    authenticatedAxiosInstance
        .get(API + `/documents/preview/pdf?s3Key=${data.s3Key}`, {
            responseType: "arraybuffer",
        })
        .then((res) => res.data)
