import { useContext } from "react"
import { Roles } from "../../../../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../../../../../components/authentication/RenderWhenAuthorized"
import { ModalContext } from "../../../../../components/contexts/ModalContext"
import { SidePanelContext } from "../../../../../components/contexts/SidePanelContext"

type OfferDetailButtonRowType = {
    status: "unanswered" | "settled" | "rejected" | string
    setstatus: (e: "unanswered" | "settled" | "rejected" | string) => void
    createdBillCount: number
    statusCopy: "unanswered" | "settled" | "rejected" | string
    handleChangeStatus: () => void
    handleDownload: () => void
}

export default function OfferDetailButtonRow(props: OfferDetailButtonRowType) {
    const modalCon = useContext(ModalContext)
    const sidePanelCon = useContext(SidePanelContext)
    return (
        <RenderWhenAuthorized requiresAll={[Roles.buchhaltung_read_write]}>
            <div
                className={`flex flex-wrap p-4 rounded-default items-center shadow-lg bg-white mt-4 gap-4 justify-between`}>
                {/* RADIO BUTTON */}
                {props.statusCopy === "settled" && <p className="text-gray-300 font-semibold">Das Angebot wurde angeommen.</p>}
                {props.statusCopy === "rejected" && <p className="text-gray-300 font-semibold">Das Angebot wurde abgelehnt.</p>}
                {props.statusCopy === "unanswered" && (
                    <div className="flex flex-col sm:flex-row sm:justify-center sm:items-center gap-4">
                        <div className="flex gap-2">
                            <input
                                type="radio"
                                name="radio-1"
                                className="radio"
                                radioGroup="statusGroup"
                                value={"unanswered"}
                                checked={props.status === "unanswered"}
                                onChange={(e) => props.setstatus(e.target.value)}
                            />
                            <label>Unbeantwortet</label>
                        </div>
                        <div className="flex gap-2">
                            <input
                                type="radio"
                                name="radio-2"
                                className="radio"
                                radioGroup="statusGroup"
                                value={"settled"}
                                checked={props.status === "settled"}
                                onChange={(e) => props.setstatus(e.target.value)}
                            />
                            <label>Angenommen</label>
                        </div>
                        <div className="flex gap-2">
                            <input
                                type="radio"
                                name="radio-3"
                                className="radio"
                                radioGroup="statusGroup"
                                value={"rejected"}
                                checked={props.status === "rejected"}
                                onChange={(e) => props.setstatus(e.target.value)}
                            />
                            <label>Abgelehnt</label>
                        </div>
                        {props.statusCopy !== props.status && (
                            <div className={"flex pl-8"}>
                                <button className="btn btn-primary btn-outline" onClick={props.handleChangeStatus}>
                                    Speichern
                                </button>
                            </div>
                        )}
                    </div>
                )}
                {/* BUTTON */}
                <div className="flex flex-wrap sm:flex-row justify-end w-full sm:w-fit gap-2">
                    <button onClick={() => sidePanelCon?.trigger(3)} className="hidden sm:block btn btn-primary btn-outline">
                        Vorschau
                    </button>
                    <button onClick={() => props.handleDownload()} className="hidden sm:block btn btn-primary btn-outline">
                        Download
                    </button>
                    <button onClick={() => modalCon?.trigger(0)} className="btn btn-primary btn-outline">
                        Archivieren
                    </button>
                    {props.createdBillCount === 0 && props.statusCopy === "settled" && (
                        <button onClick={() => modalCon?.trigger(1)} className="btn btn-primary btn-outline">
                            Rechnung erstellen
                        </button>
                    )}
                </div>
            </div>
        </RenderWhenAuthorized>
    )
}
