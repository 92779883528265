import { useQuery } from "@tanstack/react-query"
import ErrorPage from "../../../404/ErrorPage"
import Loading from "../../../../components/common/Loading"
import { getTransactionData } from "../../api/apiCalls"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import {
    TransactionsType,
    transactionElementType,
} from "../../types"
import { TransactionElement } from "./TransactionElement"

export default function Transactions(
    props: TransactionsType
) {
    const { data, isLoading, error } = useQuery({
        queryKey: ["transactionData"],
        queryFn: getTransactionData,
    })
    const [showAll, setshowAll] =
        useState<boolean>(false)
    const [
        displayedTransactions,
        setdisplayedTransactions,
    ] = useState<transactionElementType[]>()

    useEffect(() => {
        if (data) {
            setdisplayedTransactions(
                data?.sort(
                    (
                        a: transactionElementType,
                        b: transactionElementType
                    ) =>
                        new Date(
                            b.bookingTimestamp
                        ).getTime() -
                        new Date(
                            a.bookingTimestamp
                        ).getTime()
                )
            )
        }
    }, [data])

    return (
        <section className="w-full max-w-[50%] bg-white rounded-default shadow-lg grow h-[85vh] mt-4 p-4">
            <div className="border-b pb-2 flex justify-between">
                <h3 className="text-xl font-semibold">
                    Nicht zugeordnete Zahlungen
                </h3>
                <button
                    className="btn btn-primary btn-sm"
                    onClick={() =>
                        setshowAll(!showAll)
                    }>
                    {showAll
                        ? "Nur Unzugeordnete"
                        : "Alle Anzeigen"}
                </button>
            </div>
            <div className="overflow-y-scroll flex flex-col h-[94%] overflow-x-hidden">
                {error && <ErrorPage />}
                {isLoading && <Loading />}
                {displayedTransactions &&
                    data &&
                    !error &&
                    !isLoading && (
                        <>
                            {!showAll &&
                                displayedTransactions?.every(
                                    (obj: {
                                        processingStatus: string
                                    }) =>
                                        obj.processingStatus ===
                                        "ASSIGNED"
                                ) && (
                                    <p className="text-center pt-1 font-medium">
                                        Alle
                                        Zahlungen
                                        zugewiesen.
                                    </p>
                                )}
                            {!showAll
                                ? displayedTransactions
                                      ?.filter(
                                          (obj: {
                                              processingStatus: string
                                          }) =>
                                              obj.processingStatus ===
                                              "UNASSIGNED"
                                      )
                                      .map(
                                          (
                                              item: any,
                                              index: number
                                          ) => (
                                              <TransactionElement
                                                  key={
                                                      index
                                                  }
                                                  amount={
                                                      item
                                                          .paymentInformation
                                                          .transactionAmount
                                                          .amount
                                                  }
                                                  date={dayjs(
                                                      item.bookingTimestamp
                                                  ).format(
                                                      "DD.MM.YYYY"
                                                  )}
                                                  note={
                                                      item
                                                          .paymentInformation
                                                          .remittanceText
                                                  }
                                                  status={
                                                      item.processingStatus
                                                  }
                                                  openModal={() => {
                                                      props.openModal()
                                                      props.func(
                                                          item
                                                      )
                                                  }}
                                              />
                                          )
                                      )
                                : displayedTransactions?.map(
                                      (
                                          item: any,
                                          index: number
                                      ) => (
                                          <TransactionElement
                                              key={
                                                  index
                                              }
                                              amount={
                                                  item
                                                      .paymentInformation
                                                      .transactionAmount
                                                      .amount
                                              }
                                              date={dayjs(
                                                  item.bookingTimestamp
                                              ).format(
                                                  "DD.MM.YYYY"
                                              )}
                                              note={
                                                  item
                                                      .paymentInformation
                                                      .remittanceText
                                              }
                                              status={
                                                  item.processingStatus
                                              }
                                              openModal={() => {
                                                  props.openModal()
                                                  props.func(
                                                      item
                                                  )
                                              }}
                                          />
                                      )
                                  )}
                        </>
                    )}
            </div>
        </section>
    )
}
