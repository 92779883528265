import React, {
    createContext,
    useState,
} from "react"
import {
    DrawerContextProps,
    DrawerContextProviderProps,
} from "../Types"

export const DrawerContext = createContext<
    DrawerContextProps | undefined
>(undefined)

export const DrawerContextProvider: React.FC<
    DrawerContextProviderProps
> = ({
    children,
}: DrawerContextProviderProps) => {
    const [open, setOpen] = useState(false)

    const trigger = (e: boolean) => {
        setOpen(e)
    }

    return (
        <DrawerContext.Provider
            value={{ open, trigger }}>
            {children}
        </DrawerContext.Provider>
    )
}
