import { useContext } from "react"
import { ModalContext } from "../../../components/contexts/ModalContext"
import BaseFilterButton from "../../../components/elements/BaseFilterButton"
import { preventKeyDown } from "../../../services/functions"
import { Employee } from "../types/Types"
import EmployeeCard from "./EmployeeCard"
import EmployeeFilterModal from "./EmployeeFilterModal"
import { useNavigate } from "react-router-dom"
import { Pagination } from "antd"

type EmployeePartLeftType = {
    searchTerm: string
    setsearchTerm: (e: string) => void
    showFilter: boolean
    setShowFilter: (e: boolean) => void
    archiveString: string
    setarchiveString: (e: string) => void
    handleUseFilter: () => void
    handleFilterRemove: () => void
    positionString: string
    setpositionString: (e: string) => void
    displayedEmployee: Employee[]
    selectedEmployee: Employee
    page: number
    handlePageChange: () => void
    totalDocs: number
}

export default function EmployeePartLeft(props: EmployeePartLeftType) {
    const modalCon = useContext(ModalContext)
    const navigate = useNavigate()
    return (
        <div className="flex flex-col w-full h-80 sm:w-1/2 gap-4 sm:h-auto sm:max-h-full">
            <div className="flex flex-row w-full justify-between pr-1.5 relative items-center gap-2">
                <input
                    onKeyDown={preventKeyDown}
                    onChange={(e) => props.setsearchTerm(e.target.value)}
                    type="text"
                    value={props.searchTerm ?? null}
                    placeholder="Mitarbeiter suchen..."
                    className="w-full lg:max-w-xs rounded-default bg-none shadow-lg p-4 text-base-200"
                />
                {window.innerWidth > 860 && <BaseFilterButton func={() => props.setShowFilter(!props.showFilter)} />}
                <EmployeeFilterModal
                    visible={props.showFilter}
                    setvisible={() => props.setShowFilter(false)}
                    archived={props.archiveString}
                    setarchived={props.setarchiveString}
                    useFilter={props.handleUseFilter}
                    filterRemove={props.handleFilterRemove}
                    position={props.positionString}
                    setposition={props.setpositionString}
                />
            </div>
            {/* Body with Cards */}
            {!props.displayedEmployee || props.displayedEmployee.length === 0 ? (
                <div className="flex flex-col justify-center items-center w-full">
                    <p className="text-gray-300 uppercase font-medium pt-4">Keine Mitarbeiter angelegt</p>
                    <button className="btn btn-ghost mt-2 btn-outline max-w-xs" onClick={() => props.setsearchTerm("")}>
                        Eingabe zurücksetzen
                    </button>
                </div>
            ) : (
                <div className="inline-grid w-full grid-cols-1 lg:grid-cols-2 gap-4 overflow-y-scroll max-h-[75vh]">
                    {props.displayedEmployee?.map((item: Employee, index: number) => (
                        <EmployeeCard
                            setEmployee={() => navigate("/mitarbeiter/" + item._id)}
                            key={index}
                            item={item}
                            selected={(props.selectedEmployee?._id ?? "") === item?._id}
                            archiveFunc={() => {
                                item.archived ? modalCon?.trigger(5) : modalCon?.trigger(3)
                            }}
                            deleteFunc={function (): void {
                                modalCon?.trigger(4)
                            }}
                        />
                    ))}
                </div>
            )}
            <div className="flex justify-center w-full p-4">
                <Pagination
                    defaultCurrent={1}
                    current={props.page}
                    pageSize={20}
                    onChange={props.handlePageChange}
                    total={props.totalDocs}
                    showSizeChanger={false}
                />
            </div>
        </div>
    )
}
