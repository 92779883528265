import { useContext } from "react"
import { Roles } from "../../../../auth/RoleMapping"
import { DocumentDataType } from "../../../../components/Types"
import { RenderWhenAuthorized } from "../../../../components/authentication/RenderWhenAuthorized"
import BaseDivider from "../../../../components/elements/BaseDivider"
import BaseDocumentElement from "../../../../components/elements/BaseDocumentElement"
import BaseInputArea from "../../../../components/elements/BaseInputArea"
import BaseInputField from "../../../../components/elements/BaseInputField"
import { adressType, MailReportType } from "../../../../data/Types"
import { ModalContext } from "../../../../components/contexts/ModalContext"
import BaseCheckbox from "../../../../components/elements/BaseCheckbox"
import { validateEmail } from "../../../../services/functions"
import { SidePanelContext } from "../../../../components/contexts/SidePanelContext"
import BaseMailPreviewDataRow from "../../../../components/elements/BaseMailPreviewDataRow"

type CustomerDetailPartLeftType = {
    cname: string
    ctel: string
    cperson: string
    cmail: string
    ccomment: string
    creceiver: boolean
    cadress: adressType
    setcname: (e: string) => void
    setctel: (e: string) => void
    setcperson: (e: string) => void
    setcmail: (e: string) => void
    setccomment: (e: string) => void
    setcreceiver: (e: boolean) => void
    setcadress: (e: any) => void
    aname: string
    atel: string
    aperson: string
    amail: string
    acomment: string
    areceiver: boolean
    aadress: adressType
    setaname: (e: string) => void
    setatel: (e: string) => void
    setaperson: (e: string) => void
    setamail: (e: string) => void
    setacomment: (e: string) => void
    setareceiver: (e: boolean) => void
    setaadress: (e: any) => void
    data: any // TODO any
    setfileToDelete: (e: string) => void
    handleDownload: (e: string, f: string) => void
    handleUpdateCustomer: () => void
    setselectedS3Key: (e: string) => void
    mailReports: MailReportType[]
    setResendUri: (e: string) => void
    setMailPreviewContent: (e: string) => void
}

export default function CustomerDetailPartLeft(props: CustomerDetailPartLeftType) {
    const modalCon = useContext(ModalContext)
    const sidePanelCon = useContext(SidePanelContext)
    console.log(props.mailReports)
    return (
        <div className="flex flex-col w-full bg-white rounded-default shadow-lg py-4 px-2  h-[80vh] overflow-y-scroll">
            <div className="flex flex-row gap-4  ">
                {/* CUSTOMER */}
                <div className="flex flex-col w-full h-fit">
                    <BaseInputField
                        label={"Besitzer *"}
                        type={"text"}
                        value={props.cname ?? props.data.customer?.customer?.name}
                        onChange={props.setcname}
                    />
                    <BaseInputField
                        label={"Telefon *"}
                        type={"text"}
                        value={props.ctel ?? props.data.customer.customer?.tel}
                        onChange={props.setctel}
                        classProps="pt-2"
                    />
                    <BaseInputField
                        label={"Ansprechpartner"}
                        type={"text"}
                        value={props.cperson ?? props.data.customer.customer?.person}
                        onChange={props.setcperson}
                        classProps="pt-2"
                    />
                    <BaseInputField
                        label={"E-Mail-Adresse *"}
                        type={"text"}
                        status={!validateEmail(props.cmail) ? "error" : undefined}
                        value={props.cmail ?? props.data.customer.customer?.mail}
                        onChange={props.setcmail}
                        classProps="pt-2"
                    />
                    <BaseInputArea
                        label={"Bemerkung"}
                        value={props.ccomment ?? props.data.customer?.customer?.comment}
                        onChange={props.setccomment}
                        classProps="pt-2"
                    />
                    <BaseCheckbox
                        check={props.creceiver}
                        disabled={props.creceiver !== props.areceiver && props.creceiver}
                        setcheck={props.setcreceiver}
                        label={"erhält Rechnungen & Angebote"}
                        bold
                        classProps="mt-8"
                    />
                    <BaseDivider classProps="mb-4 mt-4" />
                    <div className="flex flex-row gap-4">
                        <BaseInputField
                            label={"Straße *"}
                            type={"text"}
                            value={props.cadress?.street ?? props.data.customer?.customer?.adress.street}
                            onChange={(newStreet) =>
                                props.setcadress((prevAdress: any) => {
                                    return {
                                        ...prevAdress,
                                        street: newStreet,
                                    }
                                })
                            }
                        />
                        <BaseInputField
                            label={"Nummer *"}
                            width="w-48"
                            type={"text"}
                            value={props.cadress?.nr ?? props.data.customer?.customer?.adress.nr}
                            onChange={(newNr) =>
                                props.setcadress((prevAdress: any) => {
                                    return {
                                        ...prevAdress,
                                        nr: newNr,
                                    }
                                })
                            }
                        />
                    </div>
                    <div className="flex flex-row gap-4 pt-2">
                        <BaseInputField
                            label={"PLZ *"}
                            width="w-64"
                            type={"text"}
                            value={props.cadress?.plz ?? props.data.customer?.customer?.adress.plz}
                            onChange={(newPlz) =>
                                props.setcadress((prevAdress: any) => {
                                    return {
                                        ...prevAdress,
                                        plz: newPlz,
                                    }
                                })
                            }
                        />
                        <BaseInputField
                            label={"Stadt *"}
                            type={"text"}
                            value={props.cadress?.city ?? props.data.customer?.customer?.adress.city}
                            onChange={(newCity) =>
                                props.setcadress((prevAdress: any) => {
                                    return {
                                        ...prevAdress,
                                        city: newCity,
                                    }
                                })
                            }
                        />
                    </div>
                </div>
                {/* ADMINISTRATOR */}
                <div className="flex flex-col w-full h-fit">
                    <BaseInputField
                        label={"Verwalter *"}
                        type={"text"}
                        value={props.aname ?? props.data.customer?.administrator?.name}
                        onChange={props.setaname}
                    />
                    <BaseInputField
                        label={"Telefon *"}
                        type={"text"}
                        value={props.atel ?? props.data.customer.administrator?.tel}
                        onChange={props.setatel}
                        classProps="pt-2"
                    />

                    <BaseInputField
                        label={"Ansprechpartner"}
                        type={"text"}
                        value={props.aperson ?? props.data.customer.administrator?.person}
                        onChange={props.setaperson}
                        classProps="pt-2"
                    />
                    <BaseInputField
                        label={"E-Mail-Adresse *"}
                        type={"text"}
                        status={!validateEmail(props.amail) ? "error" : undefined}
                        value={props.amail ?? props.data.customer.administrator?.mail}
                        onChange={props.setamail}
                        classProps="pt-2"
                    />
                    <BaseInputArea
                        label={"Bemerkung"}
                        value={props.acomment ?? props.data.customer?.administrator?.comment}
                        onChange={props.setacomment}
                        classProps="pt-2"
                    />
                    <BaseCheckbox
                        check={props.areceiver}
                        setcheck={props.setareceiver}
                        label={"erhält Rechnungen & Angebote"}
                        bold
                        disabled={props.creceiver !== props.areceiver && props.areceiver}
                        classProps="mt-8"
                    />

                    <BaseDivider classProps="my-4" />
                    <div className="flex flex-row gap-4">
                        <BaseInputField
                            label={"Straße *"}
                            type={"text"}
                            value={props.aadress?.street ?? props.data.customer?.administrator?.adress.street}
                            onChange={(newStreet) =>
                                props.setaadress((prevAdress: any) => {
                                    return {
                                        ...prevAdress,
                                        street: newStreet,
                                    }
                                })
                            }
                        />
                        <BaseInputField
                            label={"Nummer *"}
                            width="w-48"
                            type={"text"}
                            value={props.aadress?.nr ?? props.data.customer?.administrator?.adress.nr}
                            onChange={(newNr) =>
                                props.setaadress((prevAdress: any) => {
                                    return {
                                        ...prevAdress,
                                        nr: newNr,
                                    }
                                })
                            }
                        />
                    </div>
                    <div className="flex flex-row gap-4 pt-2">
                        <BaseInputField
                            label={"PLZ *"}
                            width="w-64"
                            type={"text"}
                            value={props.aadress?.plz ?? props.data.customer?.administrator?.adress.plz}
                            onChange={(newPlz) =>
                                props.setaadress((prevAdress: any) => {
                                    return {
                                        ...prevAdress,
                                        plz: newPlz,
                                    }
                                })
                            }
                        />
                        <BaseInputField
                            label={"Stadt *"}
                            type={"text"}
                            value={props.aadress?.city ?? props.data.customer?.administrator?.adress.city}
                            onChange={(newCity) =>
                                props.setaadress((prevAdress: any) => {
                                    return {
                                        ...prevAdress,
                                        city: newCity,
                                    }
                                })
                            }
                        />
                    </div>
                </div>
            </div>
            <BaseDivider classProps="mt-8 mb-4" />
            <div className="flex flex-col pt-2">
                <label className="text-sm pl-2 pb-1">Dokumente</label>
                {props.data?.customer?.documents?.additional?.map((item: DocumentDataType, index: number) => (
                    <BaseDocumentElement
                        key={index}
                        title={item.title}
                        timestamp={item.timestamp}
                        roles={[Roles.kunden_read_write]}
                        downloadFunc={() => props.handleDownload(item.s3Key, item.title)}
                        deleteFunc={() => {
                            props.setfileToDelete(item.s3Key)
                            modalCon?.trigger(1)
                        }}
                        showPreviewFunc={() => {
                            props.setselectedS3Key(item.s3Key)
                            sidePanelCon?.trigger(3)
                        }}
                    />
                ))}
                {props.data?.customer?.documents?.additional?.length === 0 && <p className="pl-4 text-xs">Keine Dokumente vorhanden</p>}

                <RenderWhenAuthorized requiresAll={[Roles.kunden_read_write]}>
                    <button
                        className="hidden md:block btn btn-sm bg-base-100 shadow-lg border-none hover:bg-white/40 mt-2 mb-4 mr-4 self-end"
                        onClick={() => modalCon?.trigger(2)}>
                        Dokument hinzufügen
                    </button>
                </RenderWhenAuthorized>
            </div>
            <RenderWhenAuthorized requiresAll={[Roles.kunden_read_write]}>
                <button className="md:hidden btn btn-success mt-4" onClick={props.handleUpdateCustomer}>
                    Speichern
                </button>
            </RenderWhenAuthorized>
            <BaseDivider classProps="mt-8 mb-4" />
            <h5 className="text-sm pl-2 pb-1">An den Kunden verschickte Mails:</h5>
            {props.mailReports
                .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                .map((item, key) => (
                    <BaseMailPreviewDataRow
                        mailType
                        sidePanelConId={4}
                        index={props.mailReports.indexOf(item) + 1}
                        item={item}
                        setResendUri={props.setResendUri}
                        setMailPreviewContent={props.setMailPreviewContent}
                    />
                ))}
        </div>
    )
}
