import { motion } from "framer-motion"
import { useState } from "react"
import { AssignBillElementType } from "../../../types"

export function AssignBillElement(
    props: AssignBillElementType
) {
    const [check, setcheck] = useState(false)

    return (
        <div className="w-full relative">
            <motion.div
                initial={{
                    x: 0,
                    backgroundColor: "#fff",
                }}
                animate={{
                    x: check ? "-7rem" : 0,
                    backgroundColor: check
                        ? "#f2f5f8"
                        : "#fff",
                }}
                transition={{
                    duration: 0.1,
                    type: "ease",
                }}
                className="z-10 bg-white flex flex-row w-full px-4 py-2 gap-2 relative border-b items-center cursor-pointer"
                onClick={() => setcheck(!check)}>
                <div className="w-14 text-xs">
                    {props.date}
                </div>
                <div
                    className={`w-32 text-right font-bold ${
                        props.status
                            ? "text-green-600"
                            : "text-red-600"
                    }`}>
                    {props.amount.toString()} €
                </div>
                <div className="w-full pl-2 pr-8">
                    <p className="line-clamp-1 text-gray-400">
                        {props.customer}
                    </p>
                </div>
            </motion.div>
            <div
                title="Rechnung als Bezahlt markieren"
                className="cursor-pointer absolute right-0 z-0 h-full bg-green-300 w-28 top-0 flex items-center justify-center"
                onClick={() => {
                    props.func()
                    setcheck(false)
                }}>
                <p className="uppercase font-semibold tracking-wider pr-1">
                    Check
                </p>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.25 7.756a4.5 4.5 0 1 0 0 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                </svg>
            </div>
        </div>
    )
}
