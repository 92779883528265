import { motion } from "framer-motion"
import { NewOfferScreen2 } from "../../types"
import { useState } from "react"
import OfferPositions from "../addBillModal/modalComponents/OfferPositions"
export default function OfferModalScreen2({
    visible,
    position,
    setpositions,
}: NewOfferScreen2) {
    const [positioncount, setpositioncount] =
        useState<number>(1)

    const addPosition = () => {
        setpositions((prevPositions) => [
            ...prevPositions,
            {
                position_id: (
                    prevPositions.length + 1
                ).toString(),
                description: "",
                tax: 0,
                unit: "",
                amount: 1,
                singlePrice: 0,
            },
        ])
    }

    const removePosition = (
        indexToRemove: number
    ) => {
        setpositions((prevValues) =>
            prevValues.filter(
                (_, index) =>
                    index !== indexToRemove
            )
        )
    }

    const handleInputChange = (
        index: number,
        key: string,
        newValue: string
    ) => {
        setpositions((prevPositions) =>
            prevPositions.map((item, idx) =>
                idx === index
                    ? { ...item, [key]: newValue }
                    : item
            )
        )
    }

    return (
        <motion.div
            initial={{
                x: "100%",
            }}
            animate={{
                x: visible ? 0 : "100%",
            }}
            transition={{
                type: "ease",
            }}
            className="w-full h-full z-20 absolute top-0 left-0 bg-white pt-2 overflow-y-scroll pb-12 pl-2">
            {position.map((item, index) => (
                <OfferPositions
                    onChange={handleInputChange}
                    remove={removePosition}
                    index={index}
                    key={index}
                    tax={item.tax}
                    description={item.description}
                    amount={item.amount}
                    unit={item.unit}
                    singlePrice={item.singlePrice}
                    id={index.toString()}
                />
            ))}
            <div
                className="flex pl-4 text-sm items-center pt-2 gap-3 cursor-pointer"
                onClick={() => {
                    addPosition()
                    setpositioncount(
                        positioncount + 1
                    )
                }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                </svg>
                <p>Weitere Position hinzufügen</p>
            </div>
        </motion.div>
    )
}
