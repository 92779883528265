import { useContext } from "react"
import { RenderWhenAuthorized } from "../../../../components/authentication/RenderWhenAuthorized"
import { Roles } from "../../../../auth/RoleMapping"
import { ModalContext } from "../../../../components/contexts/ModalContext"
import { ReactComponent as REFRESH } from "../../../../assets/RefreshICON.svg"
type ObjectDetailPartLeftCustomerType = {
    name?: string
}

export default function ObjectDetailPartLeftCustomer(props: ObjectDetailPartLeftCustomerType) {
    const modalCon = useContext(ModalContext)
    return (
        <div className="flex pt-2 pb-4 border-b-2">
            <p className="font-bold">Kunde:</p>
            <span className="px-4">{props.name ?? "nicht zugeordnet"}</span>
            <span>
                <RenderWhenAuthorized requiresAll={[Roles.objekte_read_write]}>
                    <REFRESH className="w-6 h-6 cursor-pointer" onClick={() => modalCon?.trigger(0)} />
                </RenderWhenAuthorized>
            </span>
        </div>
    )
}
