import React, {
    createContext,
    useState,
} from "react"
import {
    ActionMessageProps,
    ActionMessageProviderProps,
} from "../Types"

export const ActionMessageContext = createContext<
    ActionMessageProps | undefined
>(undefined)

export const ActionMessageContextProvider: React.FC<
    ActionMessageProviderProps
> = ({
    children,
}: ActionMessageProviderProps) => {
    const [alert, setAlert] = useState<{
        visible: boolean
        type:
            | "add"
            | "update"
            | "delete"
            | "resend"
            | "download"
            | ""
        information: string
        err: boolean
    }>({
        visible: false,
        type: "",
        information: "",
        err: false,
    })

    // Function to trigger the animation from other components
    const alertAnimation = (
        type:
            | "add"
            | "update"
            | "delete"
            | "download"
            | "resend",
        information: string,
        err?: boolean
    ) => {
        setAlert({
            visible: true,
            type: type,
            information: information,
            err: err ?? false,
        })
        setTimeout(() => {
            setAlert({
                visible: false,
                type: type,
                information: information,
                err: err ?? false,
            })
        }, 3000)
        setTimeout(() => {
            setAlert({
                visible: false,
                type: "",
                information: "",
                err: false,
            })
        }, 3500)
    }

    return (
        <ActionMessageContext.Provider
            value={{ alert, alertAnimation }}>
            {children}
        </ActionMessageContext.Provider>
    )
}
