import { useKeycloak } from "@react-keycloak/web"
import { Roles } from "../../auth/RoleMapping"
import ErrorPage from "../../pages/404/ErrorPage"
import NotAuthorized from "../common/NotAuthorized"

export type RenderWhenAuthorizedProps = {
    requiresAll?: Roles[]
    requiresOneOf?: Roles[]
    page?: boolean
    children: any
}

export const RenderWhenAuthorized = ({
    children,
    requiresAll,
    requiresOneOf,
    page,
}: RenderWhenAuthorizedProps) => {
    const { keycloak } = useKeycloak()
    // check if requiresAll and requiresOneOf are empty arrays and set them to undefined
    if (requiresAll?.length === 0)
        requiresAll = undefined
    if (requiresOneOf?.length === 0)
        requiresOneOf = undefined

    // check if user is authenticated
    const isAuthenticated = keycloak.authenticated
    // check if user has all required roles
    const meetsRequiresAll = requiresAll
        ? requiresAll.every((role) =>
              keycloak.hasRealmRole(role)
          )
        : true
    const meetsRequiresOneOf = requiresOneOf
        ? requiresOneOf.some((role) =>
              keycloak.hasRealmRole(role)
          )
        : true
    // generate boolean to check if user is authorized
    const isAuthorized =
        meetsRequiresAll && meetsRequiresOneOf
    // return children if user is authenticated and authorized to view content
    return isAuthenticated && isAuthorized ? (
        children
    ) : page ? (
        <NotAuthorized />
    ) : null
}
