import { useState } from "react"
import BaseModal from "../../../components/layout/BaseModal"
import { useQueryClient } from "@tanstack/react-query"
import { useCreateObjectContractMutation } from "../api/services"
import BaseInputField from "../../../components/elements/BaseInputField"
import { Customer } from "../../Kunden/types/Types"
import dayjs from "dayjs"

type EditOBVModalType = {
    modalId: number
    customer: Customer
    object: string
    objectId: string
    data: any // TODO
}

export default function EditOBVModal(props: EditOBVModalType) {
    const [price, setprice] = useState<string>()
    const [contractStart, setcontractStart] = useState<string>()

    const queryClient = useQueryClient()
    const { mutate: createObv } = useCreateObjectContractMutation(queryClient, props.modalId)
    const sendOBV = () => {
        console.log({
            obj: props.objectId,
            cus: props.customer._id ?? "",
            contract_start: dayjs(contractStart).format() ?? String(new Date()),
            price: Number(price),
        })
        createObv({
            obj: props.objectId,
            cus: props.customer._id ?? "",
            contract_start: dayjs(contractStart).format() ?? String(new Date()),
            price: Number(price),
        })
    }

    return (
        <BaseModal
            size
            modalId={props.modalId}
            title="Objektbetreuungsvertrag erstellen"
            bottomRow={
                <div className="flex justify-end">
                    <button className="btn btn-success" onClick={sendOBV}>
                        Abschicken
                    </button>
                </div>
            }>
            <BaseInputField
                label={"Objekt"}
                type={"text"}
                disabled
                value={props.object}
                onChange={function (e: string): void {
                    throw new Error("Function not implemented.")
                }}
                classProps="mt-2"
            />

            <BaseInputField
                label={"Kunde"}
                type={"text"}
                disabled
                value={
                    props.customer.customer.name +
                    " | " +
                    props.customer.customer.adress?.street +
                    " " +
                    props.customer.customer.adress?.nr +
                    ", " +
                    props.customer.customer.adress?.plz +
                    " " +
                    props.customer.customer.adress?.city
                }
                onChange={function (e: string): void {
                    throw new Error("Function not implemented.")
                }}
            />
            <BaseInputField label={"Vertragsbeginn"} type={"Date"} onChange={setcontractStart} />
            <BaseInputField label={"Preis"} type={"number"} onChange={setprice} placeholder="Preis eintragen" classProps="mb-2" />
        </BaseModal>
    )
}
