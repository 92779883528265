import { motion } from "framer-motion"
import { useState } from "react";
import { ModalTaskScreen3Type } from "../../types/Types";

const tasks = [
    {id: '1', task: 'Hecke schneiden', must: false },
    {id: '2', task: 'Hecke schneiden', must: false },
    {id: '3', task: 'Hecke schneiden', must: false },
    {id: '4', task: 'Hecke schneiden', must: false },
]

export default function ModalTaskScreen3({ visible }: ModalTaskScreen3Type) {

    const [checkedStates, setCheckedStates] = useState(new Array(tasks?.length).fill(false));

    // TODO implement function to set item.must to checkbox value to know when to display hint text
    const handleCheckboxChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newCheckedStates = [...checkedStates];
        newCheckedStates[index] = event.target.checked;
        setCheckedStates(newCheckedStates);
    };


    return (
        <motion.div
            initial={{ 
                x: '100%'
            }}
            animate={{
                x: visible ? 0 : '100%' 
            }}
            transition={{
                type: 'ease'
            }}
            className='w-full h-full z-30 absolute top-0 left-0 bg-white pt-2 overflow-y-scroll pb-4 pl-2'
        >
            <h3 className="underline text-lg italic">Welche Aufgaben müssen zwingend erfüllt werden, bevor Fertigstellung?</h3>
            {tasks.map((item, index) => (
                <div key={item.id} className="flex flex-row pt-2 items-center gap-4 pl-4">
                    <input type="checkbox" checked={checkedStates[index]} onChange={handleCheckboxChange(index)} className="checkbox border-black" />
                    <p className="truncate" title={item.task}>
                        {item.task}
                        { !item.must &&
                            <span className="text-xs text-gray-400 pl-3">(nicht zwingend notwendig)</span>
                        }
                    </p>
                </div>
            ))}
        
        </motion.div>    
    )
}
