
import '../../style/loading.css'

export default function Loading() {
  return (
    <section className='h-full w-full bg-white overflow-hidden '>
		<div className="loader"></div>
    </section>
  )
}
