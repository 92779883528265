import { useMutation } from "@tanstack/react-query"
import { sendHelpMessage } from "./apiCalls"
type HelpMessageType = {
    topic: string
    description: string
}
export const useSendHelpMutation = () => {
    return useMutation<
        unknown,
        Error,
        HelpMessageType
    >({
        mutationFn: sendHelpMessage,

        onError: (err) => {
            console.log(err)
        },
    })
}
