import { QueryClient, useMutation } from "@tanstack/react-query"
import { postNewOffer, statusOffer, archiveOffer, postNewBill, archiveBill, cancelBill, solvePaymentEscalation } from "./apiCalls"
import { useContext } from "react"
import { ActionMessageContext } from "../../../components/contexts/ActionMessageContext"

// ADD Offer
export const useAddOfferMutation = (queryClient: QueryClient, modalCon: any, clearInput: () => void) => {
    const alert = useContext(ActionMessageContext)
    return useMutation<unknown, Error, any>({
        mutationFn: postNewOffer,
        onSuccess: () => {
            clearInput()
            modalCon?.trigger(0)
            alert?.alertAnimation("add", "Angebot")
            queryClient.invalidateQueries({
                queryKey: ["offerData"],
            })
        },
        onError: (err) => {
            clearInput()
            modalCon?.trigger(0)
            alert?.alertAnimation("add", "Angebot", true)
            console.log(err)
        },
    })
}

export const useUpdateStatusOfferMutation = (queryClient: QueryClient) => {
    const alert = useContext(ActionMessageContext)
    return useMutation<unknown, Error, { id: string; body: { status: string } }>({
        mutationFn: statusOffer,
        onSuccess: (data, variables) => {
            alert?.alertAnimation("update", "Angebotsstatus")
            queryClient.invalidateQueries({
                queryKey: ["singleOffer" + variables?.id],
            })
        },
        onError: (err) => {
            alert?.alertAnimation("update", "Angebotsstatus", true)
            console.log(err)
        },
    })
}

export const useArchiveOfferMutation = (queryClient: QueryClient, modalCon: any, modalId: number) => {
    const alert = useContext(ActionMessageContext)
    return useMutation<
        unknown,
        Error,
        {
            id: string
            body: { archived: boolean }
        }
    >({
        mutationFn: archiveOffer,
        onSuccess: (data, variables) => {
            alert?.alertAnimation("update", "Angebotsstatus")
            modalCon?.trigger(modalId)
            queryClient.invalidateQueries({
                queryKey: ["singleOffer" + variables?.id],
            })
        },
        onError: (err) => {
            alert?.alertAnimation("update", "Angebotsstatus", true)
            console.log(err)
        },
    })
}

// ====================================================== BILL ======================================================  //

export const useAddBillMutation = (queryClient: QueryClient, modalCon: any, clearInput: () => void, offer_id?: string) => {
    const alert = useContext(ActionMessageContext)
    return useMutation<unknown, Error, any>({
        mutationFn: postNewBill,
        onSuccess: () => {
            clearInput()
            modalCon?.trigger(0)
            alert?.alertAnimation("add", "Rechnung")
            queryClient.invalidateQueries({
                queryKey: ["billData"],
            })
            queryClient.invalidateQueries({
                queryKey: ["paymentsData"],
            })
            queryClient.invalidateQueries({
                queryKey: ["singleOffer" + offer_id],
            })
        },
        onError: (err) => {
            clearInput()
            modalCon?.trigger(0)
            alert?.alertAnimation("add", "Rechnung", true)
            console.log(err)
        },
    })
}

export const useArchiveBillMutation = (queryClient: QueryClient, modalCon: any, modalId: number) => {
    const alert = useContext(ActionMessageContext)
    return useMutation<
        unknown,
        Error,
        {
            id: string
            body: { archived: boolean }
        }
    >({
        mutationFn: archiveBill,
        onSuccess: (data, variables) => {
            alert?.alertAnimation("update", "Rechnungsstatus")
            modalCon?.trigger(modalId)
            queryClient.invalidateQueries({
                queryKey: ["singleBill" + variables?.id],
            })
        },
        onError: (err) => {
            alert?.alertAnimation("update", "Rechnungsstatus", true)
            console.log(err)
        },
    })
}

export const useCancelBillMutation = (queryClient: QueryClient, modalCon: any, modalId: number) => {
    const alert = useContext(ActionMessageContext)
    return useMutation<
        unknown,
        Error,
        {
            id: string
            body: { canceled: boolean }
        }
    >({
        mutationFn: cancelBill,
        onSuccess: (data, variables) => {
            alert?.alertAnimation("update", "Rechnungsstatus")
            modalCon?.trigger(modalId)
            queryClient.invalidateQueries({
                queryKey: ["singleBill" + variables?.id],
            })
        },
        onError: (err) => {
            alert?.alertAnimation("update", "Rechnungsstatus", true)
            console.log(err)
        },
    })
}

// ====================================================== PAYMENT REQUEST ======================================================  //
export const useSolveEscalationMutation = (queryClient: QueryClient, modalCon: any, modalId: number) => {
    const alert = useContext(ActionMessageContext)
    return useMutation<
        unknown,
        Error,
        String
    >({
        mutationFn: solvePaymentEscalation,
        onSuccess: (data, variables) => {
            alert?.alertAnimation("update", "Rechnungsstatus")
            modalCon?.trigger(modalId)
            queryClient.invalidateQueries({
                queryKey: ["paymentsData"],
            })
        },
        onError: (err) => {
            alert?.alertAnimation("update", "Rechnungsstatus", true)
            console.log(err)
        },
    })
}