import { Helmet } from "react-helmet"
import { BaseLayoutType } from "../Types"
import { RenderWhenAuthorized } from "../authentication/RenderWhenAuthorized"

export default function BaseLayout({
    title,
    children,
    roles,
}: BaseLayoutType) {
    return (
        <section className="h-screen w-full flex flex-col items-center justify-start">
            <Helmet>
                <title>
                    Besitzwerk GmbH | {title}
                </title>
            </Helmet>
            <RenderWhenAuthorized
                requiresAll={roles}>
                {children}
            </RenderWhenAuthorized>
        </section>
    )
}
