import axios from "axios";

export const authenticatedAxiosInstance = axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
})

export const updateKcToken = (token: string) => {
    authenticatedAxiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
}