import BaseModal from "../../../../../components/layout/BaseModal"

type SolveEscalationModalType = {
    modalId: number
    solveEscalation: () => void
}

export default function SolveEscaltionModal(props: SolveEscalationModalType) {
    return (
        <BaseModal
            title={"Zahlung wurde beglichen"}
            size
            modalId={props.modalId}
            bottomRow={
                <div className="flex justify-end w-full">
                    <button onClick={props.solveEscalation} className="btn btn-success">
                        Bestätigen
                    </button>
                </div>
            }>
            <p className="py-4">Sie haben das Problem manuell geklärt. Die Zahlungsanforderung wurde beglichen. Bitte bestätigen Sie den Vorgang.</p>
        </BaseModal>
    )
}
