import { TableRowDetailPageType } from "../../types"

export default function TableRowDetailPage({
    pos,
    description,
    descriptionDetail,
    amount,
    unit,
    singleprice,
    tax,
}: TableRowDetailPageType) {
    return (
        <tr className="z-0 border-b border-gray-200 text-sm relative overflow-hidden">
            <td>
                <p className="">{pos}</p>
            </td>
            <td className="hidden sm:table-cell">
                <p className="line-clamp-2">
                    {description} <br />{" "}
                    <span className="text-xs text-gray-400">
                        {descriptionDetail}
                    </span>
                </p>
            </td>
            <td>
                <p className="text-center line-clamp-1">
                    {amount}
                </p>
            </td>
            <td className="hidden sm:table-cell">
                <p className="text-center line-clamp-1">
                    {unit}
                </p>
            </td>
            <td>
                <p className="text-center line-clamp-1">
                    {singleprice
                        .toFixed(2)
                        .toString()
                        .replace(".", ",")}{" "}
                    €
                </p>
            </td>
            <td className="hidden sm:table-cell">
                <p className="text-center line-clamp-1">
                    {tax} %
                </p>
            </td>
            <td>
                <p className="text-center line-clamp-1">
                    {(singleprice * amount * (1 + 0.01 * tax))
                        .toFixed(2)
                        .toString()
                        .replace(".", ",")}{" "}
                    €
                </p>
            </td>
        </tr>
    )
}
