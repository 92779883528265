import { updateKcToken } from "./AuthenticatedAxios";

/**
 *  This function is called when there is an event emitted by the keycloak provider.
 * @param event the event that was emitted
 * @param error the error that was emitted
 */
export const eventLogger = (event: unknown, error: unknown) => {
    //console.log("onKeycloakEvent", event, error);
    // TODO handle error
};

/**
 *  This function is called when the token is refreshed (or initially created).
 * @param tokens 
 */
export const tokenLogger = (tokens: any) => {
    updateKcToken(tokens.token)
};