import { useContext } from "react"
import { ActionMessageContext } from "../../../components/contexts/ActionMessageContext"
import { QueryClient, useMutation } from "@tanstack/react-query"
import { setReminder, updateTransactionAndBillData, updateTransactionAndBillPullData } from "./apiCalls"

export const useUpdateTransactionMutation = (queryClient: QueryClient, modalCon: any, modalClose?: boolean) => {
    const alert = useContext(ActionMessageContext)
    return useMutation<
        unknown,
        Error,
        {
            pid: string
            tid: string
        }
    >({
        mutationFn: updateTransactionAndBillData,
        onSuccess: () => {
            alert?.alertAnimation("update", "Status Zahlung")
            modalCon?.trigger(1)
            queryClient.invalidateQueries({
                queryKey: ["paymentsData"],
            })
            queryClient.invalidateQueries({
                queryKey: ["transactionData"],
            })
        },

        onError: (err) => {
            alert?.alertAnimation("update", "Status Zahlung", true)
            console.log(err)
        },
    })
}

export const useUpdateTransactionPullMutation = (queryClient: QueryClient, modalCon: any) => {
    const alert = useContext(ActionMessageContext)
    return useMutation<
        unknown,
        Error,
        {
            pid: string
            tid: string
        }
    >({
        mutationFn: updateTransactionAndBillPullData,
        onSuccess: () => {
            alert?.alertAnimation("update", "Status Zahlung")
            modalCon?.trigger(1)
            queryClient.invalidateQueries({
                queryKey: ["paymentsData"],
            })
            queryClient.invalidateQueries({
                queryKey: ["transactionData"],
            })
        },

        onError: (err) => {
            alert?.alertAnimation("update", "Status Zahlung", true)
            console.log(err)
        },
    })
}

export const useSetReminderMutation = (queryClient: QueryClient, modalCon: any, modalId: number, billId: string) => {
    const alert = useContext(ActionMessageContext)
    return useMutation<
        unknown,
        Error,
        String
    >({
        mutationFn: setReminder,
        onSuccess: (data, variables) => {
            alert?.alertAnimation("update", "Mahnstatus")
            modalCon?.trigger(modalId)
            queryClient.invalidateQueries({
                queryKey: ["paymentsData"],
            })
            queryClient.invalidateQueries({
                queryKey: ["PaymentRequirements" + billId],
            })
        },
        onError: (err) => {
            alert?.alertAnimation("update", "Rechnungsstatus", true)
            console.log(err)
        },
    })
}
