import { QueryClient, useMutation } from "@tanstack/react-query"
import { ObjectType } from "../types/types"
import {
    createObjectServiceContract,
    deleteDocFromObject,
    postNewDocToObject,
    updateEmployeeObject,
    updateListOfServicesOfObject,
    updateObject,
} from "./apiCalls"
import { useContext } from "react"
import { ActionMessageContext } from "../../../components/contexts/ActionMessageContext"
import { ModalContext } from "../../../components/contexts/ModalContext"

// update object data
export const useUpdateObjectMutation = (queryClient: QueryClient) => {
    const alert = useContext(ActionMessageContext)
    return useMutation<unknown, Error, { id: string; body: ObjectType }>({
        mutationFn: updateObject,
        onSuccess: (data, variables) => {
            alert?.alertAnimation("update", "Objekt")
            queryClient.invalidateQueries({
                queryKey: ["singleObjectData" + variables.id],
            })
        },
        onError: (err) => {
            alert?.alertAnimation("update", "Objekt", true)
            console.log(err)
        },
    })
}

// object employee list of object
export const useUpdateEmployeeObjectMutation = (queryClient: QueryClient, modalCon: any, modalConId: number) => {
    const alert = useContext(ActionMessageContext)
    return useMutation<
        unknown,
        Error,
        {
            id: string
            body: { employee_ids: string[] }
        }
    >({
        mutationFn: updateEmployeeObject,
        onSuccess: (data, variables) => {
            alert?.alertAnimation("update", "Mitarbeiterliste")
            modalCon?.trigger(modalConId)
            queryClient.invalidateQueries({
                queryKey: ["singleObjectData" + variables.id],
            })
        },
        onError: (err, variables) => {
            console.log(variables)
            alert?.alertAnimation("update", "Mitarbeiterliste", true)
            console.log(err)
        },
    })
}

// update list of services of object
export const useUpdateListOfServiesOfObjectMutation = (queryClient: QueryClient, modalConId: number) => {
    const alert = useContext(ActionMessageContext)
    const modalCon = useContext(ModalContext)
    return useMutation<
        unknown,
        Error,
        {
            id: string
            serviceList: any
        }
    >({
        mutationFn: updateListOfServicesOfObject,
        onSuccess: (data, variables) => {
            alert?.alertAnimation("update", "Leistungsverzeichnis")
            modalCon?.trigger(modalConId)
            queryClient.invalidateQueries({
                queryKey: ["singleObjectData" + variables.id],
            })
        },
        onError: (err) => {
            alert?.alertAnimation("update", "Leistungsverzeichnis", true)
            console.log(err)
        },
    })
}

// create service object contract
export const useCreateObjectContractMutation = (queryClient: QueryClient, modalConId: number) => {
    const alert = useContext(ActionMessageContext)
    const modalCon = useContext(ModalContext)
    return useMutation<
        unknown,
        Error,
        {
            obj: string
            cus: string
            contract_start: string
            price: number
        }
    >({
        mutationFn: createObjectServiceContract,
        onSuccess: (data, variables) => {
            alert?.alertAnimation("add", "Objektbetreuungsvertrag")
            modalCon?.trigger(modalConId)
            queryClient.invalidateQueries({
                queryKey: ["singleObjectData" + variables.obj],
            })
        },
        onError: (err) => {
            alert?.alertAnimation("add", "Objektbetreuungsvertrag", true)
            console.log(err)
        },
    })
}

// upload document(s) to object
export const useAddDocToObjectMutation = (queryClient: QueryClient) => {
    const alert = useContext(ActionMessageContext)
    return useMutation<unknown, Error, { id: string; data: any }>({
        mutationFn: postNewDocToObject,
        onSuccess: (data, variables) => {
            alert?.alertAnimation("add", "Dokument")
            queryClient.invalidateQueries({
                queryKey: ["singleObjectData" + variables.id],
            })
        },
        onError: (err) => {
            alert?.alertAnimation("add", "Dokument", true)
            console.log(err)
        },
    })
}

// delete doc-data from object & from s3
export const usePatchDocObjectMutation = (queryClient: QueryClient, modalCon: any, modalConId: number) => {
    const alert = useContext(ActionMessageContext)
    return useMutation<unknown, Error, { id: string; file: string }>({
        mutationFn: deleteDocFromObject,
        onSuccess: (data, variables) => {
            alert?.alertAnimation("delete", "Dokument")
            modalCon?.trigger(modalConId)
            queryClient.invalidateQueries({
                queryKey: ["singleObjectData" + variables.id],
            })
        },
        onError: (err) => {
            alert?.alertAnimation("delete", "Dokument", true)
            console.log(err)
        },
    })
}
