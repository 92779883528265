import { API } from "../../../api/constants"
import { ObjectType } from "../types/types"
import { authenticatedAxiosInstance } from "../../../auth/AuthenticatedAxios"

export const getAllObjectData = () => authenticatedAxiosInstance.get(API + "/object/get/all").then((res) => res.data)

export const getSingleObjectData = (id: string) => authenticatedAxiosInstance.get(API + "/object/get/" + id).then((res) => res.data)

export const updateObject = (data: { id: string; body: ObjectType }) =>
    authenticatedAxiosInstance.put(API + "/object/update/" + data.id, data.body).then((res) => res.data)

export const updateEmployeeObject = (data: { id: string; body: { employee_ids: string[] } }) =>
    authenticatedAxiosInstance.patch(API + "/object/employee/update/" + data.id, data.body).then((res) => res.data)

export const updateListOfServicesOfObject = (data: { id: string; serviceList: any }) =>
    authenticatedAxiosInstance.patch(API + "/object/listofservices/update/" + data.id, data.serviceList).then((res) => res.data)

export const createObjectServiceContract = (data: { obj: string; cus: string; contract_start: string; price: number }) =>
    authenticatedAxiosInstance.post(API + "/object/servicecontract/create/" + data.obj, data).then((res) => res.data)

// TODO Datatype any
// add Doc to Customer
export const postNewDocToObject = (data: { id: string; data: any }) =>
    authenticatedAxiosInstance
        .patch(API + "/object/doc/array/" + data.id, data.data, { headers: { "Content-Type": "multipart/form-data" } })
        .then((res) => res.data)

// delete Doc from single Customer
export const deleteDocFromObject = (data: { id: string; file: string }) =>
    authenticatedAxiosInstance.patch(API + "/object/doc/delete/" + data.id, { file: data.file }).then((res) => res.data)
